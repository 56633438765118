import { LiveIcon } from "assets/svg";
import cls from "classnames";
import { Loading } from "components";
import "./button.scss";

const Button = ({ children, className, onClick, court }) => {
  // change className prop to class names in button.scss to check styles
  return (
    <button className={cls("defaultButton", className)} onClick={onClick}>
      {court ? children.status !== "submitted" && children.status : children}
      {children.status === "live" && (
        <span className="live-icon-wrapper">
          <LiveIcon />
        </span>
      )}
      {children.status === "submitted" && <Loading small={true} />}
    </button>
  );
};

export default Button;
