import React from "react";
import ReactPaginate from "react-paginate";
import {
  useFilters,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { AngleRight, FirstPage, AngleLeft, LastPage } from "assets/svg";
import "./style.scss";

const perPageOptions = ["2", "5", "10", "20", "50", "100"];
const Table = ({
  columns,
  data,
  tableForm,
  links,
  meta,
  totalPages,
  onPerPageChange,
  handlePageChange,
}) => {
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data,
      tableForm,
      initialState: {},
      autoResetSelectedRows: false,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
    useRowSelect
  );

  return (
    <div>
      <div
        className={"react-table-container table users"}
        style={{ margin: "20px 2rem 40px" }}
      >
        <div className="thead">
          <div {...headerGroups[0].getHeaderGroupProps()} className="tr">
            {headerGroups &&
              headerGroups[0].headers.map((column, index) => {
                return (
                  <div {...column.getHeaderProps()} key={index} className="th">
                    {column.Header}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="tbody">
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <div key={index} className="tr" style={{ display: "flex" }}>
                {row.cells.map((cell, indx) => {
                  const { Component, mapper, name, onClick, ...rest } =
                    cell.column;
                  return (
                    <Component
                      key={indx}
                      field={row}
                      name={name}
                      onClick={onClick}
                      mapper={mapper}
                      {...rest}
                      className="td"
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="table-footer">
        <div className="per-page-wrapper">
          <div>Per Page:</div>
          <select onChange={(e) => onPerPageChange(e.target.value)}>
            {perPageOptions.map((item, index) => (
              <option
                key={index}
                value={item}
                selected={item === meta.per_page}
                label={item}
              />
            ))}
          </select>
        </div>
        <div className="pagination-wrapper">
          <button
            className={
              meta.current_page === 1
                ? "first-last-icons disabled"
                : "first-last-icons"
            }
            
          >
            <img src={FirstPage} disabled={meta.current_page === 1} alt="previous" height={14} onClick={() => handlePageChange(1)}/>
          </button>
          <ReactPaginate
            pageCount={totalPages}
            marginPagesDisplayed={2}
            breakLabel={"..."}
            forcePage={meta.current_page -1}
            onPageChange={(e) => handlePageChange(e.selected + 1)}
            pageRangeDisplayed={3}
            nextLabel={<img src={AngleRight} alt="nexr" height={20} />}
            previousLabel={<img src={AngleLeft} alt="previous" height={20} />}
          />
          <button
            className={
              meta.current_page === meta.last_page
                ? "first-last-icons disabled"
                : "first-last-icons"
            }
            // disabled={meta.current_page === meta.last_page}
            // onClick={() => handlePageChange(meta.last_page)}
          >
            <img src={LastPage} alt="previous" height={14} disabled={meta.current_page === meta.last_page} onClick={() => handlePageChange(meta.last_page)}/>
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default Table;
