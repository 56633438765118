import React, { useState } from "react";
import {
  useTable,
  useRowSelect,
  useFlexLayout,
  useSortBy,
  useFilters,
} from "react-table";
import { useSticky } from "react-table-sticky";
import cls from "classnames";

import { ButtonWithIcon } from "components";
import Table from "./Components/Table/Table";
import {
  CrossIconWhite,
  TrashIcon,
  CopyIcon,
  CrossRotatedIcon,
  PasteWhiteIcon,
  CopyWhiteIcon,
  CrossRotateWhiteIcon,
} from "assets/svg";

import "./audience.scss";

const readClipoardAndParseText = async () => {
  const text = await navigator.clipboard.readText();
  if (text.trim()) {
    let lines = text.split("\n");
    const data = [];
    lines.forEach((line) => {
      let cell = line.split("\t");
      let newCat = {
        first_name: cell[0],
        last_name: cell[1],
        email: cell[2],
      };
      data.push(newCat);
    });
    return data;
  }
  return;
};

const CellComponent = ({
  value,
  column,
  handleChangeDataValue,
  row,
  handlePasteData,
  isCellEditable,
  ...props
}) => {
  switch (column.id) {
    case "action":
      if (!isCellEditable) {
        return (
          <div className="actions-block" style={{ width: "max-content" }}>
            <button className="action-btn">
              <TrashIcon />
            </button>
          </div>
        );
      } else {
        return null;
      }
    default:
      if (isCellEditable) {
        return (
          <input
            type="text"
            className="editable-cell"
            placeholder={`${column.Header} Here`}
            name={column.id}
            value={value}
            onChange={(e) => {
              handleChangeDataValue(e.target.value, column.id, row.index);
            }}
            onPaste={async (e) => {
              const dataFromClipboard = await readClipoardAndParseText();
              handlePasteData(dataFromClipboard);
            }}
          />
        );
      } else {
        return <div className="uneditable-cell">{value}</div>;
      }
  }
};

const GroupButton = ({ className }) => {
  return (
    <button className={cls("groupButton", className)}>
      <label>
        <input type="checkbox" />
        Coaches
      </label>
      <span>2</span>
    </button>
  );
};

const defaultColumn = {
  Cell: CellComponent,
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          style={{ width: "27px", height: "27px" }}
        />
      </>
    );
  }
);

const insertColumns = (columns) => {
  const selectionCol = {
    id: "selection",
    accessor: () => "select",
    disableSortBy: true,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Footer: ({ getToggleAllRowsSelectedProps }) => {
      return (
        <div style={{ width: "max-content", margin: "0 auto" }}>
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
      );
    },
    Cell: ({ row }) => (
      <div style={{ width: "max-content", margin: "0 auto" }}>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
  };
  const defaultCols = [selectionCol, ...columns];
  return defaultCols;
};

const Audience = () => {
  const [rowsData, setRowsData] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      isInitial: true,
    },
  ]);
  const [isCellEditable, setIsCellEditable] = useState(true);
  const columns = React.useMemo(() => columnsData, []);
  const data = React.useMemo(() => rowsData, [rowsData]);
  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      handleChangeDataValue,
      handlePasteData,
      isCellEditable,
      autoResetSelectedRows: false,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push(insertColumns);
    }
  );

  const isInitial = data.length === 1 && data[0].isInitial;

  async function handlePasteClick() {
    const dataFromClipboard = await readClipoardAndParseText();
    handlePasteData(dataFromClipboard);
  }

  function handlePasteData(newData) {
    setIsCellEditable(false);
    setRowsData((old) => {
      if (isInitial) {
        return [...newData];
      } else {
        return [...old, ...newData];
      }
    });
  }

  function handleChangeDataValue(value, columnId, rowIndex) {
    setRowsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }
  return (
    <div className="audienceCont">
      <div className="audienceCont-tools">
        <ButtonWithIcon disabled disableIcon={CopyWhiteIcon} icon={CopyIcon}>
          Copy Selected
        </ButtonWithIcon>
        <ButtonWithIcon
          onClick={handlePasteClick}
          bgColor="#3399FA"
          color="white"
          icon={PasteWhiteIcon}
        >
          Paste
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled
          disableIcon={CrossRotateWhiteIcon}
          icon={CrossRotatedIcon}
        >
          Delete Selected
        </ButtonWithIcon>
        <ButtonWithIcon bgColor="#3399FA" color="white" icon={CrossIconWhite}>
          Add Row
        </ButtonWithIcon>
      </div>
      <div className="audienceCont-board">
        <div className="audienceCont-left-side">
          <button className="audienceCont-create-group">
            <CrossIconWhite width={15} height={15} />
            Create New Group
          </button>
          <div className="audienceCont-group-block">
            <GroupButton />
            <GroupButton className="active" />
            <GroupButton />
            <GroupButton />
            <GroupButton />
            <GroupButton />
          </div>
        </div>
        <div className="audienceCont-right-side">
          <Table {...table} />
        </div>
      </div>
    </div>
  );
};

export default Audience;

const columnsData = [
  {
    Header: "First Name",
    accessor: "first_name",
  },
  {
    Header: "Last Name",
    accessor: "last_name",
  },
  {
    Header: "Email",
    accessor: "email",
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "action",
    disableSortBy: true,
  },
];
