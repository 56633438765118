import { replace } from "connected-react-router";

import axios from "api/axios";
import {
  LOGIN__SET_USER,
  LOGIN__SET_ERROR,
  LOGIN__SET_LOADING,
  LOGIN__SET_USER_ROLE,
} from "store/constants";
import { loginApi } from "api";
import { getUserRole } from "api";
import { redirectAdminApi } from "api/auth/login";
// axios.defaults.headers.common[
//   'Authorization'
// ] = `Bearer ${res.data.access_token}`;

const defaultBody = {
  grant_type: "password",
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  scope: "*",
};

const toRedirect = () => {
  const credentials = JSON.parse(localStorage.getItem("credentials"));

  return window.location.pathname === "/"
    ? credentials.role === "admin"
      ? "/admin"
      : "/app"
    : window.location.pathname;
};

const setCredentialsInLocalStorage = (data) => {
  const jsonData = JSON.stringify(data);
  localStorage.setItem("credentials", jsonData);
};

const setDefaultHeaderForAxios = (accessToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

const actions = {
  setLoading: (payload) => ({
    type: LOGIN__SET_LOADING,
    payload,
  }),
  setLoggedIn: (payload) => ({
    type: LOGIN__SET_USER,
    payload,
  }),
  setError: (payload) => ({
    type: LOGIN__SET_ERROR,
    payload,
  }),
  setRole: (payload) => ({
    type: LOGIN__SET_USER_ROLE,
    payload,
  }),
};

export const login = (formData) => async (dispatch) => {
  dispatch(actions.setLoading(true));
  try {
    const res = await loginApi({ ...defaultBody, ...formData });
    setDefaultHeaderForAxios(res.data.access_token);
    const roleRes = await getUserRole();

    const user = roleRes.data?.data;

    setCredentialsInLocalStorage({
      ...res.data,
      role: user.role,
      email: user.email,
      logo: user.logo,
    });

    dispatch(
      actions.setRole({
        userRole: user.role,
        userEmail: user.email,
        logo: user.logo,
      })
    );

    dispatch(actions.setLoggedIn(true));
    dispatch(replace(user.role === "admin" ? "/admin" : "/app"));
  } catch (err) {
    if (err.response.status === 400) {
      dispatch(actions.setError("Incorrect login or password"));
    } else {
      dispatch(actions.setError(err.message));
    }
  } finally {
    dispatch(actions.setLoading(false));
  }
};

export const logOut = () => async (dispatch) => {
  localStorage.removeItem("credentials");
  dispatch(actions.setLoggedIn(false));
  dispatch(replace("/"));
};

export async function init(store) {
  const { dispatch } = store;
  const credentials = JSON.parse(localStorage.getItem("credentials"));
  if (credentials) {
    setDefaultHeaderForAxios(credentials.access_token);
    dispatch(actions.setLoggedIn(true));
    dispatch(
      actions.setRole({
        userRole: credentials.role,
        userEmail: credentials.email,
      })
    );
    dispatch(replace(toRedirect()));
  }
}

export const adminRedirect = (id) => async (dispatch) => {
  dispatch(actions.setLoading(true));
  try {
    const res = await redirectAdminApi(id);
    setDefaultHeaderForAxios(res.data.access_token);
    const roleRes = await getUserRole();
    const user = roleRes.data?.data;
    setCredentialsInLocalStorage({
      ...res.data,
      role: user.role,
      email: user.email,
      logo: user.logo,
    });
    dispatch(
      actions.setRole({
        userRole: user.role,
        userEmail: user.email,
        logo: user.logo,
      })
    );
    dispatch(actions.setLoggedIn(true));
    dispatch(replace(user.role === "admin" ? "/admin" : "/app"));
  } catch (err) {
    dispatch(actions.setError(err.message));
  } finally {
    dispatch(actions.setLoading(false));
  }
};
