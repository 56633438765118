import axios from "api/axios";

export const loginApi = (data) => {
  return axios.post("/oauth/token", data);
};

export const resetPasswordApi = (email) => {
  return axios.post("/auth/forgot-password", email);
};

export const redirectAdminApi = (user_id) => {
  return axios.post(`/admin/user/login/${user_id}`);
};
