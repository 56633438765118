import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";

import "./dateColumnFooter.scss";
import {
    detectSessionTime,
    frequency,
    frequency_daily,
    frequency_monthly,
    frequency_one_time,
    frequency_weekly
} from "../../../../helpers/dashboard";

const DateColumnFooter = ({ footerFormData, setFooterFormData, column }) => {

  const handleDayChange = (selectedDay, modifiers) => {
    let newDay = new Date();

    const newDate = {
      start_date: footerFormData.start_date,
      end_date: footerFormData.end_date,
    }

    if (!moment(selectedDay).isBefore(newDay)) {
      newDay = selectedDay;
    }

    switch (footerFormData.frequency) {
      case frequency_one_time:
        newDate.start_date = newDay;
        newDate.end_date = newDay;
        break;
      case frequency_daily:
      case frequency_weekly:
      case frequency_monthly:
      default:
        newDate[column.id] = newDay
        break;
    }

    if (column.id === 'start_date') {
      newDate.end_date = frequency(footerFormData.frequency, newDate)

      if (moment(newDate.end_date).isBefore(newDay)) {
        newDate.end_date = newDay;
      }
    }

    newDate.session_time = detectSessionTime(newDate.start_date)

    setFooterFormData((old) => {
      return {
        ...old,
        ...newDate
      };
    });
  };

  const isEndDateAndDisabled =
    column.id === "end_date" && footerFormData.frequency === "1";

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth());

  return (
    <DayPickerInput
        // keepFocus={false} // For testing TODO REMOVE
        // hideOnDayClick={false} // For testing TODO REMOVE
      format="LL"
      placeholder="Choose Date"
      formatDate={formatDate}
      parseDate={parseDate}
      value={footerFormData[column.id]}
      dayPickerProps={{
        selectedDays: footerFormData[column.id],
        fromMonth: lastMonth,
        disabledDays: {
          before: column.id === "end_date" ? footerFormData.start_date : new Date()
        }
      }}
      inputProps={{ disabled: isEndDateAndDisabled }}
      onDayChange={handleDayChange}
    />
  );
};

export default DateColumnFooter;
