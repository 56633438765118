const BellOnIcon = props => {
  let { fill } = props;

  return (
    <svg width="16" height="22" viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg" fill={fill || "black"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2V2.28988C12.8915 3.15043 15 5.82898 15 9V16H16V18H0V16H1V9C1 5.82898 3.10851 3.15043 6 2.28988V2C6 0.895431 6.89543 0 8 0C9.10457 0 10 0.895431 10 2ZM3 16H13V9C13 6.23858 10.7614 4 8 4C5.23858 4 3 6.23858 3 9V16ZM10 20V19H6V20C6 21.1046 6.89543 22 8 22C9.10457 22 10 21.1046 10 20Z"
        fill="fill"
      />
    </svg>
  );
};

const BellOffIcon = props => {
  let { fill } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={fill || "black"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
        fill="fill"
      />
      <path d="M0.5 0.5L23 23" stroke={fill || "black"} strokeWidth="2" />
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { BellOnIcon, BellOffIcon };
