import { USER_SETTINGS_GET } from "store/constants";
import { changeUserSettingsApi, getUserSettingsApi } from "api";

const actions = {
  setUserSettings: payload => ({
    type: USER_SETTINGS_GET,
    payload,
  }),
};

export const getUserSettings = () => async dispatch => {
  try {
    const response = await getUserSettingsApi();
    dispatch(actions.setUserSettings(response.data.data));
  } catch (err) {
    //
  } finally {
  }
};

export const changeUserSettings = data => async dispatch => {
  try {
    const res = await changeUserSettingsApi(data);
    dispatch(actions.setUserSettings(res.data.data));
    const { logo } = res.data.data;
    const local = JSON.parse(localStorage.getItem("credentials"));
    local.logo = logo;
    localStorage.setItem("credentials", JSON.stringify(local));
  } catch (error) {
    console.error(error);
  }
};
