import "./confirmationPopUp.scss";

const ConfirmationPopUp = ({
  title,
  description,
  isShow,
  negativeActonLabel = "Cancel",
  positiveActionLabel = "Confirm",
  handleNegativeCallBack,
  handlePositiveCallBack,
}) => {
  if (!isShow) return null;

  const handleNegativeAction = () => {
    handleNegativeCallBack();
  };

  const handlePositiveAction = () => {
    handlePositiveCallBack();
  };

  return (
    <div className="confirmationPopUp">
      <div className="content">
        <div className="text-block">
          {title && <p>{title}</p>}
          {description.split("\r").map(
            (t, i) =>
              t.length > 0 && (
                <p key={i} className="description">
                  {t}
                </p>
              )
          )}
        </div>
        <div className="actions-block">
          {handleNegativeCallBack && (
            <button type="button" className="negative" onClick={handleNegativeAction}>
              {negativeActonLabel}
            </button>
          )}
          {handlePositiveCallBack && (
            <button type="button" className="positive" onClick={handlePositiveAction}>
              {positiveActionLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;
