import CopyUrlIcon from "assets/svg/components/CopyUrlIcon";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./buttonLabel.scss";

const ButtonLabel = ({ children, value }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(value.stream_url).then(() => {
      toast.success("The stream URL has been copied to your clipboard.", {
        className: "toast-success",
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
      });
      setIsHovered(false);
    });
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={!isHovered ? "labelContainer" : "labelContainerHovered"}
    >
      {children}
      {isHovered && (value.status === "live" || value.status === "ready") && (
        <div onClick={handleCopyUrl} className="btn-label">
          <span>
            <CopyUrlIcon />
            Copy URL
          </span>
        </div>
      )}
      {isHovered && value.status === "submitted" && (
        <div className="btn-label">
          <span>Waiting</span>
        </div>
      )}
    </div>
  );
};

export default ButtonLabel;
