import Switch from "react-switch";
import cloneDeep from "lodash.clonedeep";

import "./switchCell.scss";

const SwitchCell = ({ value, tableForm, setTableForm, column, row }) => {
  const handleFormChange = () => {
    const cloneForm = cloneDeep(tableForm);
    cloneForm[row.index][column.id] = Number(value) === 0 ? column._id : 0;
    setTableForm(cloneForm);
  };

  return (
    <div className="switchcell-wrapper">
      <Switch
        width={49}
        height={26}
        onChange={handleFormChange}
        checked={!value ? false : true}
        onColor="#000"
        offColor="#A9A8A8"
        uncheckedIcon={false}
        checkedIcon={false}
      />
    </div>
  );
};

export default SwitchCell;
