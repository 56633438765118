import moment from "moment";

import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import "./endDate.scss";

const EndDate = ({ value, tableForm, setTableForm, column, row }) => {
  const handleFormChange = (selectedDay) => {
    const cloneForm = [...tableForm];
    cloneForm[row.index][column.id] = selectedDay;
    setTableForm(cloneForm);
  };

  const isEndDateAndDisabled = tableForm[row.index].frequency === "1";

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth());

  return (
    <DayPickerInput
      format="ll"
      placeholder="Choose Date"
      formatDate={formatDate}
      parseDate={parseDate}
      value={value}
      dayPickerProps={{
        selectedDays: value,
        fromMonth: lastMonth,
        disabledDays: (day) =>
          moment(day).isBefore(tableForm[row.index].start_date),
      }}
      inputProps={{ disabled: isEndDateAndDisabled }}
      onDayChange={handleFormChange}
    />
  );
};

export default EndDate;
