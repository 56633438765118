import { resetPasswordApi } from "api/auth/login";
import React, { useState } from "react";

const ForgotPasswordBody = ({ setModalState, email, handleFormsChange }) => {
  const [state, setState] = useState({
    showWarning: false,
    warningText: "",
    formSent: false,
  });

  const submitPasswordReset = (e) => {
    e.preventDefault();
    const data = { email: email };
    resetPasswordApi(data)
      .then((res) => {
        if (res.status === 204) {
          setState({ ...state, formSent: true });
        }
      })
      .catch((err) => {
        const { status, data } = err.response;
        if (status === 422) {
          setState({
            ...state,
            showWarning: true,
            warningText: data.errors.email,
          });
        }
      });
  };

  return (
    <div className="forgot-password-body">
      {!state.formSent ? (
        <>
          <p className="forgot-password-label">
            Please enter the email address associated with your account. We’ll
            email you a temporary password.
          </p>
          <div className="input-wrapper">
            <input
              type="text"
              name="username"
              value={email}
              onChange={handleFormsChange}
            />
            {state.showWarning && (
              <p className="invalid-email">{state.warningText}</p>
            )}
          </div>
          <div className={"buttons-wrapper"}>
            <button
              className="cancel-forgot"
              onClick={() => setModalState(false)}
            >
              <p>Cancel</p>
            </button>
            <button className="ok-forgot" onClick={submitPasswordReset}>
              <p>Submit</p>
            </button>
          </div>
        </>
      ) : (
        <div className="form-sent">
          <p>A temporary password has been sent to</p>
          <p className="email-p">{email}</p>
          <button className="ok-forgot" onClick={() => setModalState(false)}>
            OK
          </button>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordBody;
