import { useState } from "react";
import cls from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "components";
import { login } from "store/actions";
import { logoMain } from "assets/svg";
import PasswordIcon from "assets/svg/components/PasswordIcon";
import PasswordHideIcon from "assets/svg/components/PasswordHideIcon";
import "./login.scss";
import ForgotPasswordBody from "components/Modal/ForgotPasswordBody";

const PasswordInput = ({ className, value, name, onChange, placeholder }) => {
  const [isTextVisible, setIsTextVisible] = useState(false);

  const setInputType = () => {
    return isTextVisible ? "text" : "password";
  };

  const handleToggleTextVisible = () => {
    setIsTextVisible(!isTextVisible);
  };

  return (
    <div className={cls("passwordInput", className)}>
      <input
        type={setInputType()}
        name={name}
        value={value}
        onChange={onChange}
      />
      <button
        type="button"
        className="passwordInput-hide-toggle"
        onClick={handleToggleTextVisible}
      >
        {isTextVisible ? <PasswordHideIcon /> : <PasswordIcon />}
      </button>
    </div>
  );
};

const environment = process.env.REACT_APP_ENVIRONMENT;

const Login = () => {
  const [formData, setFormData] = useState({
    username: environment === "dev" ? "user@live.com" : "",
    password: environment === "dev" ? "Ye4oKoEa3Ro9llCTlNB944" : "",
  });
  const [isClicked, setIsClicked] = useState(false);
  const [modal, setModal] = useState(false);
  const { error } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(formData));
  };

  const handleFormsChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="login">
      <div className="login-header">
        <img src={logoMain} alt="Logo" />
        <p>Livestream Manager</p>
        <p> </p>
      </div>
      <div className="login-container">
        <div className="login-content">
          <form onSubmit={handleSubmit}>
            <label className="field-label">
              Email Address
              <div className="input-wrapper">
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleFormsChange}
                />
              </div>
            </label>
            <label className="field-label">
              Password
              <PasswordInput
                name="password"
                className="input-wrapper"
                value={formData.password}
                onChange={handleFormsChange}
              />
            </label>
            <div className="login-links-block">
              <label>
                <input type="checkbox" />
                Keep me logged in
              </label>
              <span to="/" onClick={() => setModal(true)}>
                Forgot password
              </span>
              <Modal
                isOpen={modal}
                handleOpen={setModal}
                className="forgot-password"
                hideCloseIcon={true}
              >
                <ForgotPasswordBody
                  setModalState={setModal}
                  email={formData.username}
                  handleFormsChange={handleFormsChange}
                />
              </Modal>
            </div>
            <Button
              className={
                !isClicked ? "login-submit" : "login-submit login-submit-active"
              }
              onClick={() => {
                setIsClicked(true);
                setTimeout(() => {
                  setIsClicked(false);
                }, 1000);
              }}
            >
              Log in
            </Button>
          </form>
          {error && <p className="login-error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
