import { Switch, Route, Redirect } from "react-router-dom";

import { Login } from "containers";

const PublicRoutes = ({ access }) => {
  if (access) {
    return <Redirect to="/app" />;
  }

  return (
    <Switch>
      <Route path="/" component={Login} />
      <Route exact path="/login" component={Login} />
    </Switch>
  );
};

export default PublicRoutes;
