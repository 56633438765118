import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { arrowDown, arrowUp } from "assets/svg";
import { detectError } from "../../helpers";
import { setPastedRows } from "store/actions";
import "./table.scss";

const renderSortIcon = (column) => {
  if (column.isSortedDesc) {
    return <img src={arrowUp} alt="Icon" />;
  } else if (column.isSorted) {
    return <img src={arrowDown} alt="Icon" />;
  }
};

const Table = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  selectedFlatRows,
  state,
  onScroll,
  loading,
  newRows,
  setNewRows,
  incompleteDataForm,
}) => {
  const [active, setActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (newRows) {
      setActive(true);
      setTimeout(() => {
        setActive(false);
        setNewRows(0);
        dispatch(setPastedRows(0));
      }, 10000);
    }
  }, [newRows]);

  useEffect(() => {
    selectedFlatRows.length
      ? setSelectedRows(selectedFlatRows.map((row) => parseInt(row.id)))
      : setSelectedRows([]);
  }, [selectedFlatRows]);

  return (
    <div {...getTableProps()} className={"react-table-container table sticky"}>
      <>
        <div className="thead">
          {headerGroups.map((headerGroup, i) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr" key={i}>
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row, index) => {
            prepareRow(row);
            const { error, courtError, nameError } = detectError(row);
            return (
              <div
                key={row.getRowProps().key}
                {...row.getRowProps()}
                className={
                  incompleteDataForm.length &&
                  index < incompleteDataForm.length &&
                  error
                    ? "tr error"
                    : active && index < newRows
                    ? "tr active"
                    : selectedRows.includes(index)
                    ? "tr selected"
                    : "tr"
                }
              >
                {row.cells.map((cell) => {
                  return cell.column.name === "court" ? (
                    <div
                      style={{ ...cell.getCellProps().style, padding: 0 }}
                      key={cell.getCellProps().key}
                      role="cell"
                      className={courtError ? "td courtError" : "td"}
                    >
                      {cell.render("Cell")}
                    </div>
                  ) : cell.column.name === "event_name" ||
                    cell.column.name === "selection" ? (
                    <div
                      key={cell.getCellProps().key}
                      style={{
                        ...cell.getCellProps().style,
                        background: "white",
                      }}
                      data-sticky-last-left-td={true}
                      data-sticky-td={true}
                    >
                      <div
                        {...cell.getCellProps()}
                        className={nameError ? "td nameError" : "td"}
                      >
                        {cell.render("Cell")}
                      </div>
                    </div>
                  ) : (
                    <div
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                      className={nameError ? "td nameError" : "td"}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default Table;
