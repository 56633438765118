import React from 'react';

const PasswordIcon = ({ handleClick }) => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={handleClick}>
      <path
        d='M22.8538 11.0527C22.6483 10.7716 17.7526 4.17041 11.4999 4.17041C5.24715 4.17041 0.3512 10.7716 0.145951 11.0525C-0.0486505 11.3191 -0.0486505 11.6808 0.145951 11.9474C0.3512 12.2285 5.24715 18.8298 11.4999 18.8298C17.7526 18.8298 22.6483 12.2285 22.8538 11.9477C23.0487 11.681 23.0487 11.3191 22.8538 11.0527ZM11.4999 17.3133C6.89408 17.3133 2.90497 12.9319 1.7241 11.4996C2.90344 10.066 6.8842 5.68689 11.4999 5.68689C16.1055 5.68689 20.0943 10.0675 21.2757 11.5006C20.0963 12.9341 16.1156 17.3133 11.4999 17.3133Z'
        fill='black'
      />
      <path
        d='M11.4999 6.95068C8.99138 6.95068 6.95044 8.99162 6.95044 11.5002C6.95044 14.0087 8.99138 16.0496 11.4999 16.0496C14.0084 16.0496 16.0494 14.0087 16.0494 11.5002C16.0494 8.99162 14.0084 6.95068 11.4999 6.95068ZM11.4999 14.5331C9.82746 14.5331 8.46696 13.1726 8.46696 11.5002C8.46696 9.82776 9.82751 8.4672 11.4999 8.4672C13.1723 8.4672 14.5329 9.82776 14.5329 11.5002C14.5329 13.1726 13.1724 14.5331 11.4999 14.5331Z'
        fill='black'
      />
    </svg>
  );
};

export default PasswordIcon;
