import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import Routes from "routes";

import "./themes/scss/index.scss";
import "react-toastify/dist/ReactToastify.css";

export const HeaderToggleContext = createContext("");

function App({ history }) {
  const [isHeaderToggleHide, setIsHeaderToggleHide] = useState(false);
  const { loggedIn, userRole } = useSelector((state) => state.login);

  return (
    <div className="app container">
      <HeaderToggleContext.Provider
        value={[isHeaderToggleHide, setIsHeaderToggleHide]}
      >
        <ToastContainer position="top-center" autoClose={3000} />
        <Routes
          history={history}
          loggedIn={loggedIn}
          isHeaderToggleHide={isHeaderToggleHide}
          role={userRole}
        />
      </HeaderToggleContext.Provider>
    </div>
  );
}

export default App;
