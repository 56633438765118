import {
  LOGIN__SET_USER,
  LOGIN__SET_ERROR,
  LOGIN__SET_LOADING,
  LOGIN__SET_USER_ROLE,
} from "store/constants";

const initialState = {
  loggedIn: false,
  error: null,
  loading: false,
  userRole: "",
  userEmail: "",
  clubLogo: "",
};

export default function login(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN__SET_USER:
      return { ...state, loggedIn: payload, error: null };
    case LOGIN__SET_LOADING:
      return { ...state, loading: payload };
    case LOGIN__SET_ERROR:
      return { ...state, loggedIn: false, error: payload };
    case LOGIN__SET_USER_ROLE:
      return {
        ...state,
        userRole: payload.userRole,
        userEmail: payload.userEmail,
        clubLogo: payload.logo,
        error: null,
      };

    default:
      return state;
  }
}
