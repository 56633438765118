import React from "react";
import "./checkbox.scss";

const Checkbox = ({ column, handleToggleHiddenProps, resolvedRef, rest }) => {
  if (!column) {
    return (
      <label>
        <input
          className="checkbox"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          style={{ width: "27px", height: "27px" }}
        />
        <span className="checkbox-checkmark" />
        <span className="label-name" />
      </label>
    );
  }

  return (
    <label>
      <input
        type="checkbox"
        {...column.getToggleHiddenProps()}
        onChange={handleToggleHiddenProps.bind(
          null,
          column.toggleHidden,
          column._id,
          column.isVisible
        )}
        className="checkbox"
      />
      <span className="checkbox-checkmark" />
      <span className="label-name">{column.Header}</span>
    </label>
  );
};

export default Checkbox;
