import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { XIcon } from "assets/svg";
import PasswordIcon from "assets/svg/components/PasswordIcon";
import PasswordHideIcon from "assets/svg/components/PasswordHideIcon";

const UserForm = ({ formData, setFormData, user, errorBorders, setImage }) => {
  const [fileName, setFileName] = useState("No file chosen");

  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    password_confirmation: false,
  });

  useEffect(() => {
    if (user) {
      setImage(formData.account_info[5].value);
    }
  }, [formData, user]);

  useEffect(() => {
    if (user && user.logo) {
      let logo = user.logo.split("/");
      logo = logo[logo.length - 1];
      setFileName(logo);
    }
  }, [user]);

  const handleDataChange = (value, section, name, e) => {
    setFormData({
      ...formData,
      [section]: formData[section].map((obj) => {
        return obj.name === name ? { ...obj, value: value } : obj;
      }),
    });
    if (name === "logo") {
      if (e.target.files && e.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
      setFileName(value.name);
      e.target.value = "";
    }
  };

  const handleRemoveImage = (e) => {
    setFormData({
      ...formData,
      account_info: formData.account_info.map((obj) => {
        return obj.name === "logo" ? { ...obj, value: "value" } : obj;
      }),
    });
    setImage("");
    setFileName("No file chosen");
  };

  return Object.entries(formData).map((arr, i) => {
    return (
      <div key={i} className="card">
        <p>
          {(i === 0 && "Account Information") ||
            (i === 1 && "Additional Contacts") ||
            (i === 2 && "Livestream Options")}
        </p>
        <div className="inputs-wrapper">
          {arr[1].map((value, i) => {
            return (
              <span
                key={value.name}
                className={
                  errorBorders.includes(value.name) ||
                  (errorBorders.includes("password") &&
                    value.name === "password_confirmation")
                    ? "error-border"
                    : ""
                }
              >
                <label htmlFor={value.name}>{value.label}</label>
                {(value.type === "text" || value.type === "email") && (
                  <input
                    type={value.type}
                    name={value.name}
                    value={value.value}
                    onChange={(e) =>
                      handleDataChange(e.target.value, arr[0], value.name)
                    }
                  />
                )}
                {value.type === "password" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <input
                      type={passwordVisible[value.name] ? "text" : value.type}
                      name={value.name}
                      value={value.value}
                      onChange={(e) =>
                        handleDataChange(e.target.value, arr[0], value.name)
                      }
                    />
                    {passwordVisible[value.name] ? (
                      <PasswordHideIcon
                        handleClick={() =>
                          setPasswordVisible({
                            ...passwordVisible,
                            [value.name]: false,
                          })
                        }
                      />
                    ) : (
                      <PasswordIcon
                        handleClick={() =>
                          setPasswordVisible({
                            ...passwordVisible,
                            [value.name]: true,
                          })
                        }
                      />
                    )}
                  </div>
                )}
                {value.type === "file" && (
                  <>
                    <input
                      type={value.type}
                      name={value.name}
                      accept="image/*"
                      id={"file"}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleDataChange(
                          e.target.files[0],
                          arr[0],
                          value.name,
                          e
                        );
                      }}
                    />
                    <label className="label-file-input" htmlFor="file">
                      Choose a file
                    </label>
                    <p className="filename">{fileName}</p>
                    {fileName !== "No file chosen" && (
                      <img
                        src={XIcon}
                        width={15}
                        alt=""
                        height={15}
                        style={{
                          alignSelf: "center",
                          paddingLeft: 5,
                          cursor: "pointer",
                        }}
                        onClick={handleRemoveImage}
                      />
                    )}
                  </>
                )}
                {value.type === "switch" && (
                  <Switch
                    width={49}
                    height={26}
                    checked={!!value.value}
                    onChange={() =>
                      handleDataChange(Number(!value.value), arr[0], value.name)
                    }
                  />
                )}
                {value.type === "select" && (
                  <select
                    name={value.name}
                    id={value.id}
                    value={value.value}
                    onChange={(e) =>
                      handleDataChange(e.target.value, arr[0], value.name)
                    }
                  >
                    {value.options.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      );
                    })}
                  </select>
                )}
              </span>
            );
          })}
        </div>
      </div>
    );
  });
};

export default UserForm;
