import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Loading } from "components";
import cls from "classnames";
import Empty from "../Empty/Empty";
import "./table.scss";
import { setSubmittedRows } from "store/actions";

const headerProps = (props, { column }) => {
  return [...getStyles(props, column.align), column.getSortByToggleProps()];
};

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "center") => {
  return [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "center"
            ? "center"
            : "flex-start",
        alignItems: "center",
        display: "flex",
      },
    },
  ];
};

const Table = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  selectedFlatRows,
  state,
  onScroll,
  loading,
  isThereData,
  params,
  newRows,
  setNewRows,
}) => {
  const [active, setActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (newRows) {
      setActive(true);
      setTimeout(() => {
        setActive(false);
        setNewRows(0);
        dispatch(setSubmittedRows(0));
      }, 10000);
    }
  }, [newRows]);

  useEffect(() => {
    selectedFlatRows.length
      ? setSelectedRows(selectedFlatRows.map((row) => parseInt(row.id)))
      : setSelectedRows([]);
  }, [selectedFlatRows]);

  return (
    <div
      {...getTableProps()}
      className={cls("react-table-container table sticky", {
        "acceptable-records": rows.length > 4,
      })}
      onScroll={onScroll}
      style={{ width: "100%" }}
    >
      <>
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <div
                key={row.getRowProps().key}
                {...row.getRowProps()}
                className={
                  active && index < newRows
                    ? "tr active"
                    : selectedRows.includes(index)
                    ? "tr selected"
                    : "tr"
                }
              >
                {row.cells.map((cell, i) => {
                  return cell.column.name === "event_name" ||
                    cell.column.name === "selection" ? (
                    <div
                      key={cell.getCellProps().key}
                      style={{
                        ...cell.getCellProps(cellProps).style,
                        background: "white",
                      }}
                      data-sticky-last-left-td={true}
                      data-sticky-td={true}
                    >
                      <div {...cell.getCellProps(cellProps)} className="td">
                        {cell.render("Cell")}
                      </div>
                    </div>
                  ) : (
                    <div
                      {...cell.getCellProps(cellProps)}
                      className="td"
                      key={cell.getCellProps().key}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {loading && <Loading />}
        {!isThereData && !loading && <Empty emptyText={params.emptyText} />}
      </>
    </div>
  );
};

export default Table;
