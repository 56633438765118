import { WithTable } from "hook";
import { DefaultTable } from "components";

import "./past.scss";

const params = {
  isPast: 1,
  emptyText: "You do not have any completed live stream saved here",
};

const Past = () => {
  return (
    <div className="past">
      <WithTable component={DefaultTable} params={params} />
    </div>
  );
};

export default Past;
