import { toast } from "react-toastify";

import {
  DASHBOARD_ROWS__SET_ITEMS,
  DASHBOARD_ROWS__SET_ITEM,
  DASHBOARD_ROWS__UPDATE_ITEM,
  DASHBOARD_ROWS__DELETE_ITEM,
  DASHBOARD_ROWS__SET_LOADING,
  DASHBOARD_ROWS__SET_ERROR,
  DASHBOARD_ROWS__COPY_ITEMS,
  DASHBOARD_ROWS__WAS_PASTED,
  DASHBOARD_ROWS__ADD_FROM_TEMPORARY_ITEMS_TO_BASIC,
  INCOMPLETE_ROWS__COPY_ITEMS,
  DASHBOARD_COPIED_ROWS_RESET,
  DASHBOARD_SET_PASTED_ROWS,
  DASHBOARD_SET_SUBMITTED_ROWS,
} from "store/constants";
import {
  getDashboardRowsApi,
  addDashboardRowsApi,
  deleteDashboardRowApi,
  copyDashboardRowApi,
  deleteDashboardRowGroupApi,
  updateDashboardRowIsAudio,
  updateDashboardRowIsBell,
} from "api";
import moment from "moment";

const actions = {
  setLoading: (payload) => ({
    type: DASHBOARD_ROWS__SET_LOADING,
    payload,
  }),
  setItems: (payload) => ({
    type: DASHBOARD_ROWS__SET_ITEMS,
    payload,
  }),
  setItem: (payload) => ({
    type: DASHBOARD_ROWS__SET_ITEM,
    payload,
  }),
  updateItem: (payload) => ({
    type: DASHBOARD_ROWS__UPDATE_ITEM,
    payload,
  }),
  deleteItem: (payload) => ({
    type: DASHBOARD_ROWS__DELETE_ITEM,
    payload,
  }),
  copyItems: (payload) => ({
    type: DASHBOARD_ROWS__COPY_ITEMS,
    payload,
  }),
  copyItemsFromTemporaryToBasic: (payload) => ({
    type: DASHBOARD_ROWS__ADD_FROM_TEMPORARY_ITEMS_TO_BASIC,
    payload,
  }),
  wasPasted: (payload) => ({
    type: DASHBOARD_ROWS__WAS_PASTED,
    payload,
  }),
  setError: (payload) => ({
    type: DASHBOARD_ROWS__SET_ERROR,
    payload,
  }),
  copyItemsFromIncompleteRows: (payload) => ({
    type: INCOMPLETE_ROWS__COPY_ITEMS,
    payload,
  }),
  resetCopiedRows: (payload) => ({
    type: DASHBOARD_COPIED_ROWS_RESET,
    payload,
  }),
  setSubmittedRows: (payload) => ({
    type: DASHBOARD_SET_SUBMITTED_ROWS,
    payload,
  }),
  setPastedRows: (payload) => ({
    type: DASHBOARD_SET_PASTED_ROWS,
    payload,
  }),
};

export const {
  copyItems,
  wasPasted,
  copyItemsFromTemporaryToBasic,
  copyItemsFromIncompleteRows,
  resetCopiedRows,
  setSubmittedRows,
  setPastedRows,
} = actions;

export const getDashboardRows = (params) => async (dispatch) => {
  dispatch(actions.setLoading(true));
  try {
    const res = await getDashboardRowsApi(params);
    dispatch(actions.setItems(res.data.data));
  } catch (err) {
  } finally {
    dispatch(actions.setLoading(false));
  }
};

export const addDashboardRow = (data) => async (dispatch) => {
  dispatch(actions.setLoading(true));
  const formData = new FormData();

  for (let prop in data) {
    const lowerCaseProp = prop.toLowerCase();
    if (lowerCaseProp.includes("camera")) {
      if (data[prop]) {
        formData.append("courts[]", Number(data[prop]));
      }
    } else if (prop === "exclude_dates") {
      if (data[prop]) {
        data[prop].forEach((val) => {
          formData.append("exclude_dates[]", val);
        });
      }
    } else if (prop === "start_date" || prop === "end_date") {
      formData.append(prop, moment(data[prop]).toISOString());
    } else if (prop === "audience") {
      formData.append("audience", JSON.stringify(data[prop]));
    } else if (prop === "event_notify") {
      formData.append("event_notify", JSON.stringify(data[prop]));
    } else {
      formData.append(prop, data[prop]);
    }
  }

  return new Promise(async (resolve, reject) => {
    try {
      const res = await addDashboardRowsApi(formData);
      dispatch(actions.setItem(res.data.data));
      resolve(true);
    } catch (err) {
      if (err.response) {
        Object.values(err.response.data.errors).forEach((val) => {
          toast.error(val[0], { autoClose: false });
        });
      } else {
        toast.error("Something went wrong", { autoClose: false });
      }
      dispatch(actions.copyItemsFromIncompleteRows([data]));
      reject(false);
    } finally {
      dispatch(actions.setLoading(false));
    }
  });
};

export const updateDashboardIsAudio =
  (id, isAudio) => async (dispatch, getState) => {
    // dispatch(actions.setLoading(true));
    try {
      await updateDashboardRowIsAudio(id, isAudio);
      const itemsWithUpdatedItem = getState().dashboardRows.items.map((val) =>
        val.id === id ? { ...val, is_audio: isAudio } : val
      );
      dispatch(actions.updateItem(itemsWithUpdatedItem));
    } catch (err) {
      console.error("UPDATE ROW IS-AUDIO ===", err);
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const updateDashboardIsBell =
  (id, isNotify) => async (dispatch, getState) => {
    // dispatch(actions.setLoading(true));
    try {
      await updateDashboardRowIsBell(id, isNotify);
      const itemsWithUpdatedItem = getState().dashboardRows.items.map((val) =>
        val.id === id ? { ...val, is_notify: isNotify } : val
      );
      dispatch(actions.updateItem(itemsWithUpdatedItem));
    } catch (err) {
      console.error("UPDATE ROW IS-AUDIO ===", err);
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const deleteDashboardRow = (id) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  try {
    await deleteDashboardRowApi(id);
    const itemsAfterDelete = getState().dashboardRows.items.filter(
      (val) => val.id !== id
    );
    dispatch(actions.deleteItem(itemsAfterDelete));
  } catch (err) {
    console.error("DELETE ROW ERROR ==>", err);
  } finally {
    dispatch(actions.setLoading(false));
  }
};

export const deleteDashboardRowGroup =
  (deletedItemsIds) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true));
    try {
      await deleteDashboardRowGroupApi(deletedItemsIds);
      const itemsAfterDelete = getState().dashboardRows.items.filter(
        (val) => !deletedItemsIds.includes(val.id)
      );
      dispatch(actions.deleteItem(itemsAfterDelete));
    } catch (err) {
      console.error("DELETE ROW GROUP ERROR ==>", err);
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const copyDashboardRow = (rowIds) => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  const formData = new FormData();
  rowIds.forEach((val) => {
    formData.append("ids[]", val);
  });
  try {
    const res = await copyDashboardRowApi(formData);

    const withCopiedItems = [
      ...getState().dashboardRows.items,
      ...res.data.data,
    ];
    dispatch(actions.setItems(withCopiedItems));
  } catch (err) {
    console.error("DELETE ROW ERROR ==>", err);
  } finally {
    dispatch(actions.setLoading(false));
  }
};
