import Switch from "react-switch";

const SwitchFooter = ({ footerFormData, setFooterFormData, column }) => {
  const handleSwitchChange = () => {
    setFooterFormData({
      ...footerFormData,
      courts: {
        ...footerFormData.courts,
        [column._id]: !footerFormData.courts[column._id],
      },
    });
  };

  return (
    <Switch
      width={49}
      height={26}
      onChange={handleSwitchChange}
      checked={footerFormData.courts[column._id] || false}
      onColor="#000"
      offColor="#A9A8A8"
      uncheckedIcon={false}
      checkedIcon={false}
    />
  );
};

export default SwitchFooter;
