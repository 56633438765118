import cls from "classnames";

import { CloseIcon } from "assets/svg";
import "./modal.scss";

const Modal = ({
  isOpen,
  handleOpen,
  children,
  className,
  isTouched,
  setShowPopConfirm,
  hideCloseIcon,
}) => {
  const handleClose = (e) => {
    !isTouched ? handleOpen(false) : setShowPopConfirm(true);
  };

  if (!isOpen) {
    return null;
  } else {
    return (
      <div className={cls("modal", className)} onClick={handleClose}>
        <div className="content" onClick={(e) => e.stopPropagation()}>
          {!hideCloseIcon && (
            <button className="close" onClick={handleClose}>
              <CloseIcon width={20} height={20} />
            </button>
          )}
          {children}
        </div>
      </div>
    );
  }
};

export default Modal;
