import {
  DASHBOARD_ROWS__SET_ITEMS,
  DASHBOARD_ROWS__SET_ITEM,
  DASHBOARD_ROWS__UPDATE_ITEM,
  DASHBOARD_ROWS__DELETE_ITEM,
  DASHBOARD_ROWS__SET_LOADING,
  DASHBOARD_ROWS__SET_ERROR,
  DASHBOARD_ROWS__COPY_ITEMS,
  DASHBOARD_ROWS__WAS_PASTED,
  DASHBOARD_ROWS_UPDATE_ITEM_BY_SOCKET,
  DASHBOARD_ROWS__ADD_FROM_TEMPORARY_ITEMS_TO_BASIC,
  INCOMPLETE_ROWS__COPY_ITEMS,
  DASHBOARD_COPIED_ROWS_RESET,
  DASHBOARD_SET_PASTED_ROWS,
  DASHBOARD_SET_SUBMITTED_ROWS,
} from "store/constants";

const initialState = {
  items: [],
  error: null,
  loading: false,
  copiedRows: [],
  wasPasted: false,
  temporaryCopiedRows: [],
  copiedIncompleteRows: [],
  newSubmittedRows: 0,
  newPastedRows: 0,
};

export default function dashboardRows(state = initialState, { type, payload }) {
  switch (type) {
    case DASHBOARD_ROWS__SET_ITEMS:
      return { ...state, items: payload, error: null };
    case DASHBOARD_ROWS__SET_ITEM:
      return { ...state, items: [...state.items, payload], error: null };
    case DASHBOARD_ROWS__UPDATE_ITEM:
      return { ...state, items: payload, error: null };
    case DASHBOARD_ROWS__DELETE_ITEM:
      return { ...state, items: payload, error: null };
    case DASHBOARD_ROWS__ADD_FROM_TEMPORARY_ITEMS_TO_BASIC:
      const tempRow = [...state.temporaryCopiedRows];
      const additionalTempCopies = [];

      state.temporaryCopiedRows.map((item) => {
        item.cells.forEach((cell) => {
          if (item.original[cell.column.Header])
            item.original[cell.column.Header] = cell.column._id;
        });

        if (item.original.is_new) {
          item.original.is_new = false;
          additionalTempCopies.push(item);
        }

        return item;
      });

      return {
        ...state,
        copiedRows: [...tempRow, ...additionalTempCopies, ...state.copiedRows],
        // temporaryCopiedRows: [],
        error: null,
      };
    case DASHBOARD_ROWS__COPY_ITEMS:
      return { ...state, temporaryCopiedRows: payload, error: null };
    case DASHBOARD_ROWS__SET_LOADING:
      return { ...state, loading: payload };
    case DASHBOARD_ROWS__WAS_PASTED:
      return { ...state, wasPasted: payload };
    case DASHBOARD_ROWS__SET_ERROR:
      return { ...state, error: payload };
    case INCOMPLETE_ROWS__COPY_ITEMS:
      return { ...state, copiedIncompleteRows: payload };
    case DASHBOARD_COPIED_ROWS_RESET:
      return { ...state, copiedRows: payload };
    case DASHBOARD_SET_SUBMITTED_ROWS:
      return { ...state, newSubmittedRows: payload, error: null };
    case DASHBOARD_SET_PASTED_ROWS:
      return { ...state, newPastedRows: payload, error: null };
    case DASHBOARD_ROWS_UPDATE_ITEM_BY_SOCKET:
      let newItems = [...state.items];

      newItems.forEach((item, index) => {
        if (item.id === payload.id) {
          newItems[index] = payload;
        }
      });
      return { ...state, items: newItems };
    default:
      return state;
  }
}
