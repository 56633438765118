import { useRef, useState, useEffect } from "react";
import cls from "classnames";

import "./dropDown.scss";

function useOutsideClick(ref, show, func) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func, show]);
}

const DropDown = ({
  children,
  label,
  className,
  contentClassName,
  icon: Icon,
}) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, show, handleClick);

  function handleClick(e) {
    setShow(!show);
  }

  return (
    <button
      onClick={handleClick}
      ref={wrapperRef}
      className={cls("dropDown", className, { "dropDown--show": show })}
    >
      <div className="label">
        {Icon && <Icon />} <span>{label}</span>
      </div>
      {show && (
        <div
          className={cls("dropDown-content", contentClassName)}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      )}
    </button>
  );
};

export default DropDown;
