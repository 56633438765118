import React from "react";
import "./checkboxSimple.scss";

const CheckboxSimple = ({ checkbox, changeCheckboxValue }) => {
  return (
    <label className="checkbox-simple-container">
      <input
        className="checkbox"
        type="checkbox"
        name={checkbox.name}
        checked={checkbox.checked}
        onChange={changeCheckboxValue}
      />
      <span className="checkbox-checkmark" />
      <span className="label-name" />
    </label>
  );
};

export default CheckboxSimple;
