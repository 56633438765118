import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "store/actions";
import "./styles.scss";
import cls from "classnames";

const SideBar = () => {
  const [selected, setSelected] = useState(0);
  const dispatch = useDispatch();
  return (
    <div className="side-bar-container">
      <div className="side-bar-items-wrapper">
        <div className="logo">
          <NavLink className="side-bar-item" to="/">
            LOGO
          </NavLink>
        </div>
        <NavLink
          to="/admin/user"
          className={cls("side-bar-item", {
            selected: selected === 0,
          })}
          onClick={() => setSelected(0)}>
          <span className="icon users" />
          <span style={{ marginLeft: "10px" }}>Club Accounts</span>
        </NavLink>
      </div>
      <div
        className="log-out"
        onClick={() => {
          dispatch(logOut());
        }}>
        <span>Log out</span>
      </div>
    </div>
  );
};
export default SideBar;
