import { ConfirmationPopUp } from "components";
import React from "react";

const ConfirmationWrapper = ({
  setIsTouched,
  isShown,
  setIsShown,
  setShowAudienceList,
  saveAudience,
  isPast,
}) => {
  if (!isPast) {
    return (
      <ConfirmationPopUp
        isShow={isShown}
        description="Save your changes?"
        negativeActonLabel={"No"}
        positiveActionLabel={"Yes"}
        handlePositiveCallBack={() => {
          saveAudience();
          setIsShown(false);
          setIsTouched(false);
          setShowAudienceList(false);
        }}
        handleNegativeCallBack={() => {
          setIsShown(false);
          setIsTouched(false);
          setShowAudienceList(false);
        }}
      />
    );
  } else {
    return (
      <ConfirmationPopUp
        isShow={isShown}
        description="The audience list has been copied to your clipboard.
        You can paste it into a new or current audience list."
        positiveActionLabel={"OK"}
        handlePositiveCallBack={() => {
          setIsShown(false);
        }}
      />
    );
  }
};

export default ConfirmationWrapper;
