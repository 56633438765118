import "./eventName.scss";

const EventName = ({
  value,
  tableForm,
  setTableForm,
  column,
  row,
  ...props
}) => {
  const handleFromChange = (e) => {
    const { value } = e.target;
    const cloneForm = [...tableForm];
    cloneForm[row.index][column.id] = value;
    setTableForm(cloneForm);
  };
  return (
    <div className="eventName defaultCell">
      <input
        className="event-name-input"
        placeholder="Event Name"
        value={tableForm[row.index][column.id]}
        onChange={handleFromChange}
      />
    </div>
  );
};

export default EventName;
