import { Route, Redirect, Switch } from "react-router-dom";

import { SideBar } from "components";
import { privateRoutes as routes } from "./config";
import "./styles.scss";
const PrivateRoutes = ({ access, basePath }) => {
  if (access) {
    return (
      <div className="adminPanelContainer">
        <SideBar />

        <Switch>
          <Route>
            {routes.map(({ path, exact, component: Component }) => (
              <Route
                key={path}
                path={`${basePath}${path}`}
                exact={exact}
                component={Component}
              />
            ))}
          </Route>
        </Switch>
      </div>
    );
  }

  return <Redirect to="/" />;
};

export default PrivateRoutes;
