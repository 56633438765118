import moment from "moment";

import "./day.scss";

const Day = ({ value }) => {
  let formatedValue = null;
  if (moment(value).isValid()) {
    formatedValue = moment(value).format("dddd");
  } else {
    formatedValue = value;
  }
  return <div className="day">{formatedValue}</div>;
};

export default Day;
