import { arrowDown, arrowUp } from "assets/svg";
import DeleteIcon from "assets/svg/components/DeleteIcon";

import "./table.scss";

const renderSortIcon = (column) => {
  if (column.isSortedDesc) {
    return <img src={arrowDown} alt="Icon" />;
  } else if (column.isSorted) {
    return <img src={arrowUp} alt="Icon" />;
  }
};

const getStyles = (props, align = "center") => {
  return [
    props,
    {
      style: {
        width: props.key.includes("selection") ? "60px" : "200px",
        flex: props.key.includes("action") ? 1 : null,
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "center"
            ? "center"
            : "flex-start",
        alignItems: "center",
        display: "flex",
      },
    },
  ];
};

const headerProps = (props, { column }) => {
  return [...getStyles(props, column.align), column.getSortByToggleProps()];
};

const cellProps = (props, { cell }) => {
  const direction = props.key.includes("action") ? "right" : "center";
  return getStyles(props, direction);
};

const Table = ({
  getTableProps,
  headerGroups,
  rows,
  prepareRow,
  rowsData,
  setRowsData,
  setIsTouched,
  isPast,
}) => {
  function handleChangeDataValue(value, columnId, rowIndex) {
    setIsTouched(true);
    setRowsData(() =>
      rowsData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...rowsData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }

  const handleDelete = (rowIndex) => {
    setRowsData(
      rowsData.filter((val, i) => {
        if (i === rowIndex && (val.first_name || val.last_name || val.email))
          setIsTouched(true);
        return i !== rowIndex;
      })
    );
  };

  return (
    <div {...getTableProps()} className="audienceTable">
      <div className="thead">
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map((column) => (
              <div {...column.getHeaderProps(headerProps)} className="th">
                {column.render("Header")}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="tbody">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <div {...row.getRowProps()} className="tr">
              <div className="tr-inner">
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps(cellProps)} className="td">
                      {isPast ? (
                        cell.render("Cell")
                      ) : (
                        <input
                          type="text"
                          value={cell.value}
                          onChange={(e) => {
                            handleChangeDataValue(
                              e.target.value,
                              cell.column.id,
                              cell.row.index
                            );
                          }}
                          onPaste={async (e) => {
                            e.preventDefault();
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {!isPast && <DeleteIcon handleClick={() => handleDelete(i)} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
