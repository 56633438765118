import { USER_SETTINGS_GET } from "store/constants";

const initialState = {
  settings: [],
};

export default function userSettings(state = initialState, { type, payload }) {
  switch (type) {
    case USER_SETTINGS_GET:
      return { ...state, settings: payload, error: null };
    default:
      return state;
  }
}
