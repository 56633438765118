import React from "react";
import Checkbox from "../../../Checkbox/Checkbox";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="checkbox-container table-checkbox">
        <Checkbox
          column={null}
          handleToggleHiddenProps={null}
          resolvedRef={resolvedRef}
          rest={rest}
        />
      </div>
    );
  }
);

export default IndeterminateCheckbox;
