import { useEffect, useState } from "react";
import moment from "moment";
import DayPicker from "react-day-picker";
import cls from "classnames";

import { Loading } from "components";
import { getDashboardRowByApi } from "api";
import { arrowRightIcon, arrowLeftIcon } from "assets/svg";
import "./rangeDate.scss";

function isLastDay(dt) {
  var test = new Date(dt.getTime()),
    month = test.getMonth();

  test.setDate(test.getDate() + 1);
  return test.getMonth() !== month;
}

function isFirstDay(dt) {
  return moment(dt).format("DD") === 1;
}

const RangeDate = ({
  date,
  setDate,
  currentRowId,
  excludedDays,
  setExcludedDays,
  handleExcludeSomethingChange,
  isPast,
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const res = await getDashboardRowByApi(currentRowId);
        if (Array.isArray(res.data.data.exclude_dates)) {
          setExcludedDays(res.data.data.exclude_dates);
        } else {
          setExcludedDays([]);
        }
      } catch (err) {
        console.error("GET DASHBOARD BY API ERROR ===>", err);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentRowId, setExcludedDays]);

  const handleDayClick = (day, modifiers) => {
    if (isPast) return;

    if (moment(day).isAfter(date.to) || moment(day).add(1, "day").isBefore(date.from)) {
      return;
    }

    const uniqieExcludedDays = Array.from(new Set([...excludedDays, moment(day).format("YYYY-MM-DD")]));

    setExcludedDays(uniqieExcludedDays);
    handleExcludeSomethingChange();
  };

  const { from, to } = date;

  const modifiers = {
    start: from,
    end: to,
    excluded: day => excludedDays?.includes(moment(day).format("YYYY-MM-DD")),
  };

  return (
    <div className="rangeDate">
      {loading ? (
        <Loading />
      ) : (
        <DayPicker
          disabledDays={{ before: from, after: to }}
          fromMonth={from}
          toMonth={to}
          className="Selectable"
          initialMonth={date.from}
          numberOfMonths={2}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          navbarElement={params => {
            const { onPreviousClick, onNextClick, showNextButton, showPreviousButton } = params;

            return (
              <div className="DayPicker-navBar">
                {showPreviousButton && (
                  <button className="previous" onClick={() => onPreviousClick()}>
                    <img src={arrowLeftIcon} alt="Icon" />
                  </button>
                )}
                {showNextButton && (
                  <button className="next" onClick={() => onNextClick()}>
                    <img src={arrowRightIcon} alt="Icon" />
                  </button>
                )}
              </div>
            );
          }}
          weekdaysShort={["S", "M", "T", "W", "T", "F", "S"]}
          renderDay={day => {
            return (
              <div
                className={cls(
                  "DayPicker-CustomDay",
                  {
                    isFirst: isFirstDay(day),
                    isLast: isLastDay(day),
                  },
                  isPast ? "DayPicker-disabled" : ""
                )}
                data-day={moment(day).format("D")}>
                {moment(day).format("D")}
              </div>
            );
          }}
          onDayClick={handleDayClick}
        />
      )}
    </div>
  );
};

export default RangeDate;
