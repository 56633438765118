import React from "react";

const DeleteIcon = ({ handleClick }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}>
      <g clipPath="url(#deleteIcon)">
        <path
          d="M6.32559 7.66406V13.3943"
          stroke="#5B5B5B"
          strokeWidth="2.0504"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9.67441 7.66406V13.3943"
          stroke="#5B5B5B"
          strokeWidth="2.0504"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M13.6186 4.24219V15.1073C13.6186 16.0747 12.8 16.8934 11.8325 16.8934H4.16742C3.19998 16.8934 2.38138 16.0747 2.38138 15.1073V4.24219"
          stroke="#5B5B5B"
          strokeWidth="1.9667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M3.49768 3.94414V2.45577C3.49768 1.48833 4.24187 0.744141 5.20931 0.744141H10.7907C11.7581 0.744141 12.5768 1.56275 12.5768 2.53019V4.01856"
          stroke="#5B5B5B"
          strokeWidth="1.9667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M0.744171 4.24219H15.3302"
          stroke="#5B5B5B"
          strokeWidth="1.9667"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="deleteIcon">
          <rect width="16" height="17.5628" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
