const AudioOnIcon = props => {
  let { fill } = props;

  return (
    <svg width="27" height="22" viewBox="0 0 27 22" xmlns="http://www.w3.org/2000/svg" fill={fill || "black"}>
      <path
        d="M18.5367 4.89111L16.8416 6.58624C17.9746 7.82904 18.6509 9.3002 18.6509 10.9997C18.6509 12.6992 17.9702 14.2802 16.8416 15.4132L18.5411 17.1127C20.2406 15.5274 21.0311 13.2657 21.1452 10.9997C21.1409 8.73808 20.3504 6.47645 18.5367 4.89111Z"
        fill="fill"
      />
      <path
        d="M26.121 10.9996H23.631C23.631 8.17143 22.4979 5.56726 20.5745 3.5296L22.274 1.83008C24.764 4.21028 26.121 7.49514 26.121 10.9996Z"
        fill="fill"
      />
      <path
        d="M22.2737 20.1695L20.5742 18.47C22.6119 16.4323 23.6307 13.8281 23.6307 11H26.1207C26.1207 14.5088 24.7637 17.7937 22.2737 20.1695Z"
        fill="fill"
      />
      <path
        d="M13.0165 22C12.6783 22 12.4544 21.8858 12.2304 21.6619L5.61236 16.0539H1.23402C0.562115 16.0539 0 15.4918 0 14.8199V7.1874C0 6.5155 0.562115 5.95339 1.23402 5.95339H5.61236L12.2304 0.341024C12.5685 0.00287652 13.1263 -0.106911 13.4644 0.117056C13.9123 0.341024 14.1363 0.788958 14.1363 1.24129V20.766C14.1363 21.2139 13.9123 21.6619 13.4644 21.8902C13.3546 22 13.1306 22 13.0165 22ZM2.46803 13.5858H6.0603C6.39844 13.5858 6.62241 13.7 6.84638 13.924L11.7824 18.074V3.93329L6.84638 8.08766C6.62241 8.31163 6.39844 8.42581 6.0603 8.42581H2.46803V13.5858Z"
        fill="fill"
      />
    </svg>
  );
};

const AudioOffIcon = props => {
  let {
    fill, //, stroke
  } = props;

  return (
    <svg width="27" height="22" viewBox="0 0 27 22" xmlns="http://www.w3.org/2000/svg" fill={fill || "black"}>
      <path
        d="M12.7827 21.3054L12.768 21.291L12.7522 21.2778L6.02506 15.6698L5.88598 15.5539H5.7049H1.25436C0.839785 15.5539 0.5 15.2079 0.5 14.8199V7.1874C0.5 6.79932 0.839785 6.45339 1.25436 6.45339H5.7049H5.88599L6.02508 6.33742L12.7567 0.725059L12.7725 0.711884L12.7872 0.697456C12.8834 0.602776 13.0185 0.534853 13.1538 0.510057C13.2933 0.484504 13.3816 0.512299 13.4179 0.535973L13.4431 0.552419L13.4702 0.565716C13.7327 0.694827 13.8739 0.953892 13.8739 1.24129V20.766C13.8739 21.0471 13.7338 21.3093 13.4667 21.4433L13.4008 21.4763L13.384 21.4922C13.3834 21.4923 13.3828 21.4924 13.3821 21.4925C13.3638 21.4955 13.341 21.4976 13.3139 21.4988C13.2866 21.4999 13.2606 21.5 13.2311 21.5C13.0369 21.5 12.9308 21.4511 12.7827 21.3054ZM2.00873 13.5858V14.0858H2.50873H6.16022C6.35437 14.0858 6.4605 14.1347 6.60861 14.2804L6.62398 14.2955L6.64059 14.3093L11.658 18.4593L12.4767 19.1364V18.074V3.93329V2.87015L11.6578 3.54817L6.64039 7.70254L6.62389 7.71621L6.60861 7.73123C6.4605 7.87694 6.35437 7.92581 6.16022 7.92581H2.50873H2.00873V8.42581V13.5858Z"
        fill="fill"
        stroke={fill || "black"}
      />
      <path d="M24.4867 6.68952L17.2258 13.8306L18.8385 15.4176L26.0994 8.27652L24.4867 6.68952Z" fill="fill" />
      <path d="M18.9232 6.76967L17.3103 8.35645L24.5701 15.4985L26.1831 13.9117L18.9232 6.76967Z" fill="fill" />
    </svg>
  );
};

export { AudioOnIcon, AudioOffIcon };
