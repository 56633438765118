import React from "react";

const FieldText = ({ field, name, mapper, className,  ...rest }) => {
  return (
    <div
      className={className}
    >
      {mapper ? mapper(field.original,name):field.original[name]}
    </div>
  );
};

export default FieldText;
