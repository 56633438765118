import cls from "classnames";

import "./buttonWithIcon.scss";

const ButtonWithIcon = ({
  icon: Icon,
  children,
  bgColor,
  color,
  className,
  disabled,
  onClick,
  disableIcon: DisableIcon,
}) => {
  const styles = {
    backgroundColor: bgColor,
    color,
  };
  return (
    <button
      disabled={disabled}
      className={cls("buttonWithIcon", className, {
        "buttonWithIcon--disabled": disabled,
      })}
      style={styles}
      onClick={onClick}>
      {disabled && DisableIcon ? <DisableIcon /> : <Icon />}
      {children}
    </button>
  );
};

export default ButtonWithIcon;
