import React from "react";

const PasswordHideIcon = ({ handleClick }) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      <path
        d="M19.5645 7.81934C18.4074 6.47688 17.0274 5.21684 15.565 4.26352L18.8192 1.00926C19.0481 0.780395 19.0481 0.409223 18.8192 0.180356C18.5904 -0.0485117 18.2192 -0.0485117 17.9903 0.180356L14.5243 3.64633C12.9927 2.82711 11.4761 2.41258 9.99958 2.41258C6.31556 2.41258 2.83349 5.03614 0.434662 7.81934C-0.145416 8.49243 -0.145416 9.50754 0.434662 10.1806C1.59173 11.5231 2.97173 12.7831 4.43419 13.7365L1.17994 16.9907C0.951029 17.2196 0.951029 17.5907 1.17994 17.8196C1.40888 18.0486 1.77997 18.0485 2.00884 17.8196L5.47482 14.3536C7.00646 15.1729 8.52302 15.5874 9.99954 15.5874C13.6836 15.5874 17.1656 12.9638 19.5645 10.1806C20.1446 9.50758 20.1446 8.49243 19.5645 7.81934ZM1.32263 9.41528C1.11525 9.17465 1.11525 8.82532 1.32263 8.58469C2.61829 7.08141 6.05751 3.58489 9.99958 3.58489C11.2907 3.58489 12.5278 3.96 13.6534 4.51727L12.0459 6.12481C11.4683 5.71254 10.7618 5.46969 9.99958 5.46969C8.05298 5.46969 6.46927 7.0534 6.46927 9C6.46927 9.76215 6.71208 10.4686 7.12435 11.0463L5.28372 12.8869C3.47529 11.7545 2.05576 10.2659 1.32263 9.41528ZM12.3576 9C12.3576 10.3002 11.2998 11.358 9.99958 11.358C9.56165 11.358 9.1515 11.2376 8.79974 11.0288L10.414 9.4145L12.0283 7.8002C12.2372 8.15192 12.3576 8.56207 12.3576 9ZM7.64157 9C7.64157 7.69981 8.69939 6.642 9.99958 6.642C10.4375 6.642 10.8477 6.76239 11.1994 6.97125L9.58513 8.58555L7.97083 10.1998C7.76197 9.84809 7.64157 9.43793 7.64157 9ZM18.6765 9.41528C17.3809 10.9186 13.9417 14.4151 9.99958 14.4151C8.70849 14.4151 7.47134 14.04 6.34576 13.4827L7.95329 11.8752C8.53091 12.2875 9.2374 12.5303 9.99958 12.5303C11.9462 12.5303 13.5299 10.9466 13.5299 9C13.5299 8.23786 13.2871 7.53137 12.8748 6.95372L14.7154 5.11309C16.5238 6.24551 17.9434 7.73411 18.6765 8.58469C18.8839 8.82532 18.8839 9.17465 18.6765 9.41528Z"
        fill="black"
      />
    </svg>
  );
};

export default PasswordHideIcon;
