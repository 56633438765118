import axios from "../axios";

export const getDashboardRowsApi = ({ isPast = 0 }) => {
  return axios.get(`/event?is_past=${isPast}`);
};

export const getDashboardRowByApi = (id) => {
  return axios.get(`/event/${id}`);
};

export const addDashboardRowsApi = (data) => {
  return axios.post("/event", data);
};

export const addDashboardAllRowsApi = (data) => {
  const preparedData = data.map((val) => {
    const courts = [];

    for (let prop in val) {
      const lowercaseProp = prop.toLowerCase();
      if (lowercaseProp.includes("camera")) {
        if (val[prop]) {
          courts.push(val[prop]);
        }
        delete val[prop];
      }

      if (
        prop === "session_time" &&
        val[prop] &&
        typeof val[prop] !== "string"
      ) {
        val[prop] = val[prop].join(",");
      }
    }
    return { ...val, courts };
  });
  return axios.post("/event/bulk", preparedData);
};

export const deleteDashboardRowApi = (id) => {
  return axios.delete(`/event/${id}`);
};

export const deleteDashboardRowGroupApi = (deletedItems) => {
  let deletedItemsQry;
  if (deletedItems) {
    deletedItemsQry = deletedItems
      .map(function (el, idx) {
        return "ids[]=" + el;
      })
      .join("&");
  }
  return axios.delete(`/event/delete?${deletedItemsQry}`);
};

export const copyDashboardRowApi = (data) => {
  return axios.post("/event/replicate", data);
};

export const patchDashboardRowExcludeDateApi = (id, excludeDates) => {
  let excludeDatesQry;
  if (excludeDates?.length > 0) {
    excludeDatesQry = excludeDates
      .map(function (el, idx) {
        return "exclude_dates[]=" + el;
      })
      .join("&");
  } else {
    excludeDatesQry = "clear_exclude_dates=1";
  }

  return axios.patch(`event/${id}/single?${excludeDatesQry}`);
};

export const updateDashboardRowIsAudio = (id, isAudio) => {
  let isAudioQry = "is_audio=" + isAudio;

  return axios.patch(`event/${id}/single?${isAudioQry}`);
};

export const updateDashboardRowIsBell = (id, isNotify) => {
  let isAudioQry = "is_notify=" + isNotify;

  return axios.patch(`event/${id}/single?${isAudioQry}`);
};
