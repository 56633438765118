// TODO CONTINUE
import React from "react";

// ALL COMMENTS CAN BE REMOVED FOR INPUT TO BECOME CONNECTED TO REDUX

// import { useDispatch, useSelector } from "react-redux";
// import { changeUserSettingInput } from "store/actions/user/userSettings";

const SettingsInput = ({ type, placeholder, propname, value, onChange }) => {
  return (
    <input type={type} placeholder={placeholder} value={value} onChange={e => onChange(propname, e.target.value)} />
  );
};

export default SettingsInput;
