import "../settings.scss";

const SelectBox = ({ children, handleSettingChange, defaultValue }) => {
  const handleSelect = (value) => {
    handleSettingChange("frequency", Number(value));
  };
  return (
    <div className="select-div">
      <select
        className="custom-select"
        onChange={(e) => handleSelect(e.target.value)}
        value={defaultValue}
      >
        {children}
      </select>
    </div>
  );
};

export default SelectBox;
