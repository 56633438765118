import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { WithTable } from "hook";
import { DefaultTable } from "components";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import "./dashboard.scss";
import { DASHBOARD_ROWS_UPDATE_ITEM_BY_SOCKET } from "store/constants";

const params = {
  isPast: 0,
  emptyText: "You do not have any live stream currently scheduled",
};

const Current = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const Echo1 = new Echo({
      broadcaster: "pusher",
      key: "5c8e405941cc666009b8",
      cluster: "ap2",
      forceTLS: true,
    });

    Echo1.channel(`club-updated`).listen("CourtUpdateStatusEvent", (e) => {
      dispatch({ type: DASHBOARD_ROWS_UPDATE_ITEM_BY_SOCKET, payload: e.data });
    });
  }, []);

  return (
    <div className="current">
      <WithTable component={DefaultTable} params={params} />
    </div>
  );
};

export default Current;
