import {
  COURT_COLUMNS__SET_ITEMS,
  COURT_COLUMNS__SET_LOADING,
  COURT_COLUMNS__SET_ERROR,
} from "store/constants";

const initialState = {
  items: [],
  error: null,
  loading: false,
};

export default function courtColumns(state = initialState, { type, payload }) {
  switch (type) {
    case COURT_COLUMNS__SET_ITEMS:
      return { ...state, items: payload, error: null };
    case COURT_COLUMNS__SET_LOADING:
      return { ...state, loading: payload };
    case COURT_COLUMNS__SET_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
}
