import React from 'react';

const SuccessIcon = ({ setShowSave }) => {
  const handleAnimationEnd = e => {
    e.animationName === 'animateCheck' &&
      setTimeout(() => {
        setShowSave(false);
      }, 1000);
  };

  return (
    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg" onAnimationEnd={handleAnimationEnd}>
      <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SuccessIcon;
