import { Dashboard, Audience, New, Past, Settings } from "containers";

export const publicRoutes = [];

export const privateRoutes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/audience",
    exact: true,
    component: Audience,
  },
  {
    path: "/new",
    exact: true,
    component: New,
  },
  {
    path: "/past",
    exact: true,
    component: Past,
  },
  {
    path: "/settings",
    exact: true,
    component: Settings,
  },
];
