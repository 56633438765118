import cloneDeep from "lodash.clonedeep";

import { excludeDatesIcon, AudienceBlackIcon } from "assets/svg";

import "./settings.scss";
import { AudioOffIcon, AudioOnIcon } from "assets/svg/components/AudioIcon";
import { BellOffIcon, BellOnIcon } from "assets/svg/components/BellIcon";

const Settings = ({
  value,
  handleOpenExcludeDateModal,
  setCurrentRowIndex,
  handleChangeIsAudio,
  tableForm,
  setTableForm,
  row,
  setShowAudienceList,
}) => {
  const handleExludeDateClick = () => {
    setCurrentRowIndex(row.index);
    handleOpenExcludeDateModal({
      from: row.original.start_date,
      to: row.original.end_date,
    });
  };
  const handleAudioClick = () => {
    const cloneForm = cloneDeep(tableForm);
    cloneForm[row.index].is_audio = cloneForm[row.index].is_audio > 0 ? 0 : 1;
    setTableForm(cloneForm);
  };

  const handleBellOnClick = () => {
    const cloneForm = cloneDeep(tableForm);
    cloneForm[row.index].is_notify = cloneForm[row.index].is_notify > 0 ? 0 : 1;
    setTableForm(cloneForm);
  };

  return (
    <div className="settings">
      <button
        className="action-btn"
        onClick={() => {
          setCurrentRowIndex(row.index);
          setShowAudienceList(true);
        }}>
        <img src={AudienceBlackIcon} alt="" />
      </button>
      <button onClick={handleExludeDateClick} className="action-btn">
        <img src={excludeDatesIcon} alt="" />
      </button>
      <button className="action-btn" onClick={handleAudioClick}>
        {Boolean(tableForm[row.index].is_audio) ? <AudioOnIcon /> : <AudioOffIcon />}
      </button>
      <button className="action-btn bell" onClick={handleBellOnClick}>
        {Boolean(tableForm[row.index].is_notify) ? <BellOffIcon /> : <BellOnIcon />}
      </button>
    </div>
  );
};

export default Settings;
