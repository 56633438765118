import { NavLink } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import cls from "classnames";
import { DropDown } from "components";

import { logOut } from "store/actions";
import { logoMain, arrowDownGray } from "assets/svg";

import "./header.scss";
import { useEffect, useState } from "react";

const Header = ({ isHeaderToggleHide }) => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.userSettings, shallowEqual);
  const [logo, setLogo] = useState("");

  useEffect(() => {
    setLogo(JSON.parse(localStorage.getItem("credentials")).logo);
  }, [settings]);

  return (
    <div
      className={cls("header", { "header-toggle-hide": isHeaderToggleHide })}
    >
      <div className="header-top-block">
        <div className="header-top-left-block">
          <NavLink to="/app" className="new">
            <img src={logoMain} alt="Logo" />
          </NavLink>
        </div>
        <div className="header-nav">
          <NavLink to="/app/new" className="new">
            <span className="icon" />
            New
          </NavLink>
          <NavLink to="/app" exact className="current">
            <span />
            Scheduled
          </NavLink>
          <NavLink to="/app/past" className="past">
            <span />
            Ended
          </NavLink>
        </div>
        <div className="header-top-right-block">
          <div className="club-logo-content">
            <img src={logo} alt="Logo" />
          </div>

          <DropDown
            className="settingsDropDown"
            contentClassName="content"
            icon={() => <img src={arrowDownGray} alt="img" />}
          >
            <NavLink
              to="/app/settings"
              className="settings-frop-down-item settings"
            >
              <span />
              Settings
            </NavLink>
            <span
              className="settings-frop-down-item log-out"
              onClick={() => {
                dispatch(logOut());
              }}
            >
              <span />
              Log out
            </span>
          </DropDown>
        </div>
      </div>
    </div>
  );
};

export default Header;
