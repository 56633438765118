import React from "react";

const FieldButton = ({ field, name, mapper,onClick, className, ...rest }) => {
  return (
    <div
      className={className}
    >
        <button className="cell-action" style={{background:name==="DELETE" ? "red": "#000a4e"}} onClick={()=>onClick(field.original)}>{name}</button>
    </div>
  );
};

export default FieldButton;
