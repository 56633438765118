import {
  getDashboardCourtColumnsApi,
  getInVisibleColumns,
  patchDashboardRowExcludeDateApi,
  updateVisibleColumns,
} from "api";
import {
  arrowDown,
  AudienceBlackIcon,
  CopyIcon,
  CopyWhiteIcon,
  CrossRotatedIcon,
  CrossRotateWhiteIcon,
  excludeDatesIcon,
  PasteBlackIcon,
  PasteIcon,
  PasteWhiteIcon,
  ShowHideIcon,
  trashIcon,
} from "assets/svg";
import { AudioOffIcon, AudioOnIcon } from "assets/svg/components/AudioIcon";
import { BellOffIcon, BellOnIcon } from "assets/svg/components/BellIcon";
import SuccessIcon from "assets/svg/components/SuccessIcon";
import cls from "classnames";
import {
  Button,
  ButtonWithIcon,
  ConfirmationPopUp,
  DropDown,
  Modal,
} from "components";
import AudiencePopup from "containers/User/Audience/AudiencePopup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useFilters,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import {
  addDashboardRow,
  copyItems,
  copyItemsFromTemporaryToBasic,
  deleteDashboardRow,
  deleteDashboardRowGroup,
  getDashboardRows,
  setPastedRows,
  updateDashboardIsAudio,
  updateDashboardIsBell,
  wasPasted,
} from "store/actions";
import { frequency } from "../../helpers/dashboard";
import Checkbox from "../Checkbox";
import {
  DateColumnFooter,
  FrequencyCell,
  IndeterminateCheckbox,
  RangeDate,
  SelectColumnFooterForDay,
  SessionTimeFooter,
  SwitchFooter,
  Table,
} from "./Components";
import ButtonLabel from "./Components/ButtonLabel/ButtonLabel";
import "./dashboard.scss";

let isPastGlobal = false;

const frequencySelectData = [
  { id: "1", value: "One Time" },
  { id: "2", value: "Daily" },
  { id: "3", value: "Weekly" },
  { id: "4", value: "Monthly" },
];

function convertDateObj(hhmmss) {
  let obj = new Date(); //creates a Date Object using the clients current time

  let [hours, minutes, seconds] = hhmmss.split(":");

  obj.setHours(+hours); // set the hours, using implicit type coercion
  obj.setMinutes(minutes); //you can pass Number or String, it doesn't really matter
  obj.setSeconds(seconds);
  return obj;
}

function EventName({ footerFormData, setFooterFormData, column }) {
  const handleInputChange = ({ target }) => {
    setFooterFormData({ ...footerFormData, [column.id]: target.value });
  };
  return (
    <input
      type="text"
      value={footerFormData[column.id]}
      className="eventNameInput"
      placeholder="Event Name"
      onChange={handleInputChange}
    />
  );
}

function FooterSubmit({ handleFooterSubmit }) {
  const handleSubmit = () => {
    handleFooterSubmit();
  };
  return <Button onClick={handleSubmit}>Submit</Button>;
}

function SelectColumnFooterForFrequency({
  footerFormData,
  setFooterFormData,
  column,
}) {
  const handleSelectChange = ({ target }) => {
    let data = [];

    if (target.value === "One Time" || target.value === "Weekly") {
      data = {
        ...footerFormData,
        [column.id]: target.value,
        day: moment(footerFormData.start_date).format("dddd"),
      };
    } else {
      data = { ...footerFormData, [column.id]: target.value };
    }

    data.end_date = frequency(target.value, footerFormData.start_date);

    setFooterFormData(data);
  };

  return (
    <div className="filter-select-block">
      <select
        className="filter-select"
        value={footerFormData[column.id]}
        onChange={handleSelectChange}
      >
        {frequencySelectData.map((val, i) => (
          <option key={i} value={val.id}>
            {val.value}
          </option>
        ))}
      </select>
      <img onClick={() => {}} src={arrowDown} alt="Icon" />
    </div>
  );
}

const insertColumns = (columns) => {
  const selectionCol = {
    id: "selection",
    sticky: "left",
    name: "selection",
    accessor: () => "select",
    disableSortBy: true,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: ({ row }) => (
      <div style={{ width: "max-content", margin: "0 auto" }}>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
    maxWidth: 80,
    minWidth: 80,
  };
  return [selectionCol, ...columns];
};

const cellRenderConfig = {
  frequency: FrequencyCell,
  court: ({ value }) => {
    if (value.status) {
      return (
        <ButtonLabel value={value}>
          <Button
            className={"table-button btn-" + value.status.toLowerCase()}
            onClick={() => {
              if (value.status === "live" || value.status === "ready") {
                window.open(value.stream_url, "_blank");
              }
            }}
            court={true}
          >
            {value}
          </Button>
        </ButtonLabel>
      );
    } else {
      return null;
    }
  },
  settings: (props) => {
    return (
      <div className="actions-block">
        <button
          className="action-btn"
          onClick={() => {
            props.setShowAudienceList(true);
            props.setCurrentRowId(props.row.original.id);
            props.setCurrentEventName(props.row.original.event_name);
          }}
        >
          <img src={AudienceBlackIcon} alt="" />
        </button>
        <button
          onClick={() => {
            props.setCurrentDateDiapason({
              from: new Date(props.row.original.start_date),
              to: new Date(props.row.original.end_date),
            });
            props.setCurrentRowId(props.row.original.id);
            if (props.row.original.exclude_dates) {
              props.setExcludedDays(props.row.original.exclude_dates);
            }
            props.setShowExcludedDates(true);
          }}
          className="action-btn"
        >
          <img src={excludeDatesIcon} alt="" />
        </button>
        <button
          className={cls("action-btn", {
            "disable-c": isPastGlobal,
          })}
          onClick={() => {
            if (isPastGlobal) return;

            const changedAudio = props.row.original.is_audio > 0 ? 0 : 1;
            props.storeDispatch(
              updateDashboardIsAudio(props.row.original.id, changedAudio)
            );
          }}
        >
          {Boolean(props.row.original.is_audio) ? (
            <AudioOnIcon fill={isPastGlobal ? "#A9A8A8" : "black"} />
          ) : (
            <AudioOffIcon fill={isPastGlobal ? "#A9A8A8" : "black"} />
          )}
        </button>
        <button
          className={cls("action-btn bell", {
            "disable-c": isPastGlobal,
          })}
          onClick={() => {
            if (isPastGlobal) return;

            const changedBell = props.row.original.is_notify > 0 ? 0 : 1;
            props.storeDispatch(
              updateDashboardIsBell(props.row.original.id, changedBell)
            );
          }}
        >
          {Boolean(props.row.original.is_notify) ? (
            <BellOnIcon fill={isPastGlobal ? "#A9A8A8" : "black"} />
          ) : (
            <BellOffIcon fill={isPastGlobal ? "#A9A8A8" : "black"} />
          )}
        </button>
      </div>
    );
  },
  copy_delete: (props) => {
    return (
      <div className="actions-block">
        <button
          className="action-btn"
          onClick={() => {
            props.toggleAllRowsSelected(false);
            props.toggleRowSelected(props.row.id);
            props.handleCopySingleRow();
          }}
        >
          <img src={PasteBlackIcon} alt="" />
        </button>
        <button
          className="action-btn"
          onClick={() => props.handleDeleteRow(props.row.original.id)}
        >
          <img src={trashIcon} alt="" />
        </button>
      </div>
    );
  },
};

const cellRender = (props) => {
  const { column, value } = props;
  const isSpecialCell = cellRenderConfig.hasOwnProperty(column.name);
  if (isSpecialCell) {
    return cellRenderConfig[column.name](props);
  } else {
    return <div>{value}</div>;
  }
};

const defaultColumn = {
  Cell: cellRender,
  minWidth: 150,
  width: 150,
  maxWidth: 400,
};

const getInitialStateForFooterForm = (columns) => {
  const initialState = {
    courts: {},
  };
  return columns.reduce((previousVal, val, index) => {
    if (val.accessor === "settings" || val.accessor === "copy_delete") {
      return previousVal;
    } else {
      if (val.name.includes("camera")) {
        previousVal.courts[val._id] = false;
      } else {
        previousVal[val.accessor] = "";
      }
      return {
        ...previousVal,
        start_date: new Date(),
        end_date: new Date(),
        frequency: frequencySelectData[0].id,
        day: moment().format("dddd"),
      };
    }
  }, initialState);
};

const useColumnRequest = (initialColumns = []) => {
  const [data, setData] = useState(initialColumns);
  const [inVisibleColumns, setInVisibleColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const columnsRes = await getDashboardCourtColumnsApi();
        const visibleColumnsRes = await getInVisibleColumns();
        columnsRes.data.data.forEach((el) => (el.Footer = SwitchFooter));
        const cloneInitialData = [...initialColumns];
        const lastItem = cloneInitialData.pop();
        const last2Item = cloneInitialData.pop();
        setData([
          ...cloneInitialData,
          ...columnsRes.data.data,
          last2Item,
          lastItem,
        ]);
        setInVisibleColumns(visibleColumnsRes.data.data);
      } catch (err) {
        setError(err);
        console.error("GET COLUMNS AND VISIBLE COLUMNS", err);
      } finally {
        setLoading(false);
      }
    })();
  }, [initialColumns]);

  return [data, inVisibleColumns, loading, error];
};

const Dashboard = ({ params }) => {
  const { isPast } = params;
  isPastGlobal = isPast;
  const [fetchedColumnsData, inVisibleColumns, columnLoading] =
    useColumnRequest(initialColumnsData);
  const {
    items: rowItems,
    loading: rowLoading,
    temporaryCopiedRows,
    newSubmittedRows,
  } = useSelector(({ dashboardRows }) => dashboardRows);
  const [localLoading, setLocalLoading] = useState(false);
  const dispatch = useDispatch();
  const columns = React.useMemo(() => fetchedColumnsData, [fetchedColumnsData]);
  const data = React.useMemo(() => rowItems, [rowItems]);
  const [showExcludedDates, setShowExcludedDates] = useState(false);
  const [showAudienceList, setShowAudienceList] = useState(false);
  //above two states better be moved to redux
  const [showPopConfirm, setShowPopConfirm] = useState(false); //state for popconfirm
  const [isTouched, setIsTouched] = useState(false); //to determine if there is change

  const [currentEventName, setCurrentEventName] = useState("");
  const [excludedDays, setExcludedDays] = useState([]);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [isCopiedSelected, setIsCopiedSelected] = useState(false);
  const [newRows, setNewRows] = useState(0);
  //state to show animation on save
  const [showSave, setShowSave] = useState(false);
  const [isShowDeleteConfirmationPopUp, setIsShowDeleteConfirmationPopUp] =
    useState({
      isShow: false,
      deletedRowId: null,
    });
  const [isShowSaveConfirmationPopUp, setIsShowSaveConfirmationPopUp] =
    useState({
      isShow: false,
      isChangedSomething: false,
    });
  // const [lastScrollTop, setLastScrollTop] = useState(0);
  const [currentDateDiapason, setCurrentDateDiapason] = useState({
    from: null,
    to: null,
  });
  const [footerFormData, setFooterFormData] = useState(
    getInitialStateForFooterForm(columns)
  );
  // const [isHeaderToggleHide, setIsHeaderToggleHide] =
  //   useContext(HeaderToggleContext);

  const handleToggleHeader = (e) => {
    // const st = e.target.scrollTop;
    //
    // if (st > lastScrollTop) {
    //   setIsHeaderToggleHide(true)
    // } else {
    //   setIsHeaderToggleHide(false)
    // }
    //
    // setLastScrollTop(st <= 0 ? 0 : st);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    allColumns,
    state,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      setShowExcludedDates,
      setShowAudienceList,
      setCurrentEventName,
      setCurrentDateDiapason,
      footerFormData,
      setFooterFormData,
      handleCopySingleRow,
      handleFooterSubmit,
      handleDeleteRow,
      setCurrentRowId,
      setExcludedDays,
      storeDispatch: dispatch,
      initialState: {
        sortBy: JSON.parse(localStorage.getItem("defaultSort")) || [],
      },
      autoResetSelectedRows: false,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push(insertColumns);
    }
  );
  const commonLoadingForTable = columnLoading || rowLoading || localLoading;

  useEffect(() => {
    if (!commonLoadingForTable && newSubmittedRows) {
      setNewRows(newSubmittedRows);
    }
  }, [commonLoadingForTable]);

  useEffect(() => {
    localStorage.setItem("defaultSort", JSON.stringify(state.sortBy));
  }, [state.sortBy]);

  useEffect(
    () => setFooterFormData(getInitialStateForFooterForm(columns)),
    [columns]
  );
  useEffect(() => {
    dispatch(getDashboardRows(params));
  }, [dispatch]);

  useEffect(() => {
    if (inVisibleColumns.length) {
      setHiddenColumns(inVisibleColumns);
    }
  }, [inVisibleColumns]);

  useEffect(() => {
    if (!selectedFlatRows.length) {
      setIsCopiedSelected(false);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (isCopiedSelected === true) {
      dispatch(copyItems(selectedFlatRows));
    }
  }, [isCopiedSelected]);

  const history = useHistory();

  function handleDeleteRow(id) {
    setIsShowDeleteConfirmationPopUp({
      isShow: true,
      deletedRowId: id,
    });
  }

  function handleExcludeSomethingChange() {
    setIsShowSaveConfirmationPopUp({
      ...isShowSaveConfirmationPopUp,
      isChangedSomething: true,
    });
  }
  function handleFooterSubmit() {
    dispatch(addDashboardRow(footerFormData)).then(() => {
      setFooterFormData(getInitialStateForFooterForm(columns));
    });
  }
  function handleCopySingleRow() {
    setIsCopiedSelected(true);
  }

  // function handleCopyItem(rows) {
  //   const ids = rows.map(val => val.original.id);
  //   dispatch(copyDashboardRow(ids));
  // }

  const resetShowSaveConfirmation = () => {
    setIsShowSaveConfirmationPopUp({
      isShow: false,
      isChangedSomething: false,
    });
  };

  const handleDeleteSelected = () => {
    if (selectedFlatRows.length) {
      setIsShowDeleteConfirmationPopUp({
        deletedRowId: null,
        isShow: true,
      });
    }
    // if (selectedFlatRows.length) {
    //   const ids = selectedFlatRows.map((val) => val.original.id);
    //   dispatch(deleteDashboardRowGroup(ids));
    // }
  };

  const handleCopySelectedRows = () => {
    if (selectedFlatRows.length) {
      setIsCopiedSelected(true);
      dispatch(copyItems(selectedFlatRows));
    }
  };

  const handlePasteSelectedRows = () => {
    dispatch(wasPasted(true));

    if (temporaryCopiedRows.length) {
      dispatch(copyItemsFromTemporaryToBasic());
      selectedFlatRows.map((val) => val.toggleRowSelected());
      dispatch(setPastedRows(temporaryCopiedRows.length));
      history.push("/app/new");
    }
  };

  const handleResetCurrentDateDiapason = () => {
    setExcludedDays([]);
    handleExcludeSomethingChange();
  };

  const handleModalAction = (value) => {
    if (!isShowSaveConfirmationPopUp.isChangedSomething) {
      setShowExcludedDates(value);
      resetShowSaveConfirmation();
      return;
    }

    setIsShowSaveConfirmationPopUp({
      ...isShowSaveConfirmationPopUp,
      isShow: true,
    });
  };

  const handleSaveExcludedDays = async (isClose = true) => {
    try {
      await patchDashboardRowExcludeDateApi(currentRowId, excludedDays);
      if (isClose) setShowExcludedDates(false);
      setShowSave(true);

      resetShowSaveConfirmation();
    } catch (err) {
      console.error("EXCLUDE DATE ERR ===>", err);
    } finally {
      // setShowSave(true);
    }
  };

  const handleToggleHiddenProps = async (toggleHidden, columnId, isVisible) => {
    // setLocalLoading(true);
    try {
      await updateVisibleColumns(columnId, isVisible);
      setLocalLoading(false);
      toggleHidden();
    } catch (err) {
      console.error("COURT SHOW/HIDE ERROR", err);
    } finally {
      setLocalLoading(false);
    }
  };

  const handleConfirmationDelete = () => {
    if (isShowDeleteConfirmationPopUp.deletedRowId) {
      dispatch(deleteDashboardRow(isShowDeleteConfirmationPopUp.deletedRowId));
    } else {
      const ids = selectedFlatRows.map((val) => val.original.id);
      dispatch(deleteDashboardRowGroup(ids));
    }
    setIsShowDeleteConfirmationPopUp({
      ...isShowDeleteConfirmationPopUp,
      isShow: false,
    });
  };

  const handleCancelConfirmationDelete = () => {
    setIsShowDeleteConfirmationPopUp({
      ...isShowDeleteConfirmationPopUp,
      isShow: false,
    });
  };

  const handleCancelConfirmationSave = () => {
    setIsShowSaveConfirmationPopUp({
      ...isShowSaveConfirmationPopUp,
      isShow: false,
    });

    setShowExcludedDates(false);
    resetShowSaveConfirmation();
  };

  return (
    <div
      className={cls("dashboardCont", {
        "header-toggled": false,
      })}
    >
      <div className="dashboardCont-tools">
        <ButtonWithIcon
          disabled={!selectedFlatRows.length}
          disableIcon={CopyWhiteIcon}
          icon={CopyIcon}
          className="tool-btn"
          onClick={handleCopySelectedRows}
        >
          Copy <span>&nbsp;{"Selected"}</span>
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={!isCopiedSelected}
          disableIcon={PasteWhiteIcon}
          onClick={handlePasteSelectedRows}
          className={isCopiedSelected ? "paste-btn" : ""}
          icon={isCopiedSelected ? PasteWhiteIcon : PasteIcon}
        >
          Paste
          <span>&nbsp;{"as New"}</span>
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={!selectedFlatRows.length}
          disableIcon={CrossRotateWhiteIcon}
          className="tool-btn"
          icon={CrossRotatedIcon}
          onClick={handleDeleteSelected}
        >
          Delete <span>&nbsp;{"Selected"}</span>
        </ButtonWithIcon>
        <DropDown
          className="showHideDropDown tool-btn"
          contentClassName="content"
          label="Show/Hide"
          icon={ShowHideIcon}
        >
          <div className="wrapper">
            {allColumns.map((column) => {
              if (
                column.name.includes("camera") ||
                column.name.includes("court")
              ) {
                return (
                  <div className="checkbox-container" key={column.id}>
                    <Checkbox
                      column={column}
                      handleToggleHiddenProps={handleToggleHiddenProps}
                    />
                  </div>
                );
              }

              return "";
            })}
          </div>
        </DropDown>
      </div>
      <Table
        loading={commonLoadingForTable}
        isThereData={!!data.length}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        newRows={newRows}
        setNewRows={setNewRows}
        prepareRow={prepareRow}
        selectedFlatRows={selectedFlatRows}
        state={state}
        onScroll={handleToggleHeader}
        params={params}
      />
      <Modal
        className="dashboardCont-date-modal"
        isOpen={showExcludedDates}
        handleOpen={handleModalAction}
      >
        <div className="wrapper">
          <div>
            <p>
              {!isPastGlobal
                ? "Click on a date to exclude it"
                : "Excluded dates are marked with a /"}
            </p>
          </div>
          <RangeDate
            date={currentDateDiapason}
            setDate={setCurrentDateDiapason}
            excludedDays={excludedDays}
            setExcludedDays={setExcludedDays}
            currentRowId={currentRowId}
            handleExcludeSomethingChange={handleExcludeSomethingChange}
            isPast={isPast}
          />
          {!isPast && (
            <div className="footer">
              <button
                className="clearBtn"
                onClick={handleResetCurrentDateDiapason}
              >
                Clear
              </button>
              <Button
                className={!showSave ? "saveBtn" : "animateSaveBtn"}
                onClick={() => handleSaveExcludedDays(false)}
              >
                <span>Save</span>
                <SuccessIcon setShowSave={setShowSave} />
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        className="audience-popup"
        isOpen={showAudienceList}
        handleOpen={setShowAudienceList}
        showPopConfirm={showPopConfirm}
        setShowPopConfirm={setShowPopConfirm}
        isTouched={isTouched}
      >
        <AudiencePopup
          isPast={isPast}
          rowId={currentRowId}
          eventName={currentEventName}
          isShown={showPopConfirm}
          setIsShown={setShowPopConfirm}
          isTouched={isTouched}
          setIsTouched={setIsTouched}
          setShowAudienceList={setShowAudienceList}
        />
      </Modal>
      <ConfirmationPopUp
        isShow={isShowDeleteConfirmationPopUp.isShow}
        description="Are you sure you want to delete?"
        negativeActonLabel="Cancel"
        positiveActionLabel="Confirm"
        handleNegativeCallBack={handleCancelConfirmationDelete}
        handlePositiveCallBack={handleConfirmationDelete}
      />
      <ConfirmationPopUp
        isShow={isShowSaveConfirmationPopUp.isShow}
        description="Save your changes?"
        negativeActonLabel="No"
        positiveActionLabel="Yes"
        handleNegativeCallBack={handleCancelConfirmationSave}
        handlePositiveCallBack={handleSaveExcludedDays}
      />
    </div>
  );
};

export default Dashboard;

const initialColumnsData = [
  {
    Header: "Event Name",
    accessor: "event_name",
    name: "event_name",
    Footer: EventName,
    sticky: "left",
    sortType: (rowA, rowB, id, desc) => {
      const valueA = rowA.original[id].toLowerCase();
      const valueB = rowB.original[id].toLowerCase();
      if (valueA > valueB) return -1;
      if (valueB > valueA) return 1;
      return 0;
    },
  },
  {
    Header: "Frequency",
    Footer: SelectColumnFooterForFrequency,
    accessor: "frequency",
    name: "frequency",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    name: "start_date",
    Footer: DateColumnFooter,
    sortType: (rowA, rowB, id, desc) => {
      if (new Date(rowA.original[id]) > new Date(rowB.original[id])) return 1;
      if (new Date(rowB.original[id]) > new Date(rowA.original[id])) return -1;
      return 0;
    },
    Cell: ({ value }) => <>{moment(value).format("LL")}</>,
  },
  {
    Header: "End Date",
    accessor: "end_date",
    name: "end_date",
    Footer: DateColumnFooter,
    Cell: ({ value }) => <>{moment(value).format("LL")}</>,
  },
  {
    Header: "Day",
    accessor: "day",
    name: "day",
    Footer: SelectColumnFooterForDay,
    sortType: (rowA, rowB, id, desc) => {
      if (
        moment().day(rowA.original[id]).format("E") >
        moment().day(rowB.original[id]).format("E")
      )
        return 1;
      if (
        moment().day(rowB.original[id]).format("E") >
        moment().day(rowA.original[id]).format("E")
      )
        return -1;
      return 0;
    },
    Cell: ({ value }) => {
      let formatedValue = null;
      if (value.toLowerCase() === "various") {
        formatedValue = value;
      } else {
        formatedValue = moment(value).format("dddd");
      }
      return <div>{formatedValue}</div>;
    },
  },
  {
    Header: "Session Time",
    accessor: "session_time",
    name: "session_time",
    Footer: SessionTimeFooter,
    Cell: ({ value }) => {
      const splitedValue = value.split(",");
      return (
        <div className="session-time-block">
          <span className="session-time">
            {moment(splitedValue[0], "hh:mm A").format("hh:mm A")}
          </span>
          <span className="session-time">
            {moment(splitedValue[1], "hh:mm A").format("hh:mm A")}
          </span>
        </div>
      );
    },
    sortType: (rowA, rowB, id, desc) => {
      const valA = convertDateObj(
        moment(rowA.original[id].split("-")[0], ["h:mm A"]).format("HH:mm:ss")
      );
      const valB = convertDateObj(
        moment(rowB.original[id].split("-")[0], ["h:mm A"]).format("HH:mm:ss")
      );

      if (valA > valB) return 1;
      if (valB > valA) return -1;
      return 0;
    },
    maxWidth: 210,
    minWidth: 210,
  },
  {
    Header: "Settings",
    Footer: FooterSubmit,
    accessor: "settings",
    name: "settings",
    disableSortBy: true,
    maxWidth: 210,
    minWidth: 210,
  },
  {
    Header: "Copy/Delete",
    Footer: FooterSubmit,
    accessor: "copy_delete",
    name: "copy_delete",
    disableSortBy: true,
    maxWidth: 210,
    minWidth: 210,
  },
];
