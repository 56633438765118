import { ADMIN_GET_USERS, ADMIN_GET_USER, ADMIN_SET_PER_PAGE, ADMIN_SET_PAGE } from "store/constants";

const initialState = {
  users: [],
  user: [],
  per_page: "100",
  page: "1",
};

export default function userSettings(state = initialState, { type, payload }) {
  switch (type) {
    case ADMIN_GET_USERS:
      return { ...state, users: payload, error: null };
    case ADMIN_GET_USER:
      return { ...state, user: payload, error: null };
    case ADMIN_SET_PAGE:
      return { ...state, page: payload, error: null };
    case ADMIN_SET_PER_PAGE:
      return { ...state, per_page: payload, error: null };
    default:
      return state;
  }
}
