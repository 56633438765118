import "./action.scss";

const Action = ({ value = "Submit", handleSubmitRow, row }) => {
  const handleSubmit = () => {
    handleSubmitRow(row.index, row.original);
  };
  return (
    <button className="action" onClick={handleSubmit}>
      {value}
    </button>
  );
};

export default Action;
