import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useFilters,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSticky } from "react-table-sticky";
import cloneDeep from "lodash.clonedeep";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Button,
  ButtonWithIcon,
  ConfirmationPopUp,
  DropDown,
  Modal,
} from "components";

// Own components
import {
  Action,
  Day,
  EndDate,
  EventName,
  Frequency,
  RangeDate,
  SessionTime,
  Settings,
  StartDate,
  SwitchCell,
  Table,
} from "./Components";

import {
  changeNewTabData,
  detectSessionTime,
  getFrequency,
  separateData,
} from "./helpers";

import {
  addDashboardAllRowsApi,
  getDashboardCourtColumnsApi,
  getInVisibleColumns,
  updateVisibleColumns,
} from "api";
import {
  addDashboardRow,
  copyItems,
  copyItemsFromTemporaryToBasic,
  wasPasted,
  copyItemsFromIncompleteRows,
  resetCopiedRows,
  setSubmittedRows,
  setPastedRows,
} from "store/actions";

import {
  CopyIcon,
  CopyWhiteIcon,
  CrossRotatedIcon,
  CrossRotateWhiteIcon,
  PasteIcon,
  PasteWhiteIcon,
  ShowHideIcon,
} from "assets/svg";

import "./new.scss";
import { getUserSettings } from "store/actions";
import AudiencePopup from "containers/User/Audience/AudiencePopup";
import PlusIcon from "assets/svg/components/PlusIcon";
import Checkbox from "../../../components/Checkbox/Checkbox";

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="checkbox-container table-checkbox">
      <Checkbox
        column={null}
        handleToggleHiddenProps={null}
        resolvedRef={resolvedRef}
        rest={rest}
      />
    </div>
  );
});

const insertColumns = (columns) => {
  const selectionCol = {
    id: "selection",
    sticky: "left",
    name: "selection",
    accessor: () => "select",
    disableSortBy: true,
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: ({ row }) => (
      <div style={{ width: "max-content", margin: "0 auto" }}>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
    maxWidth: 80,
    minWidth: 80,
  };
  return [selectionCol, ...columns];
};

const prepareCopiedRows = (copiedRows, initialTableFrom) => {
  return copiedRows.map(({ original }) => {
    const obj = {};
    Object.entries(original).forEach(([key, value]) => {
      const preparedKey = key.replace(/[0-9 ]/g, "").toLowerCase();
      if (initialTableFrom.hasOwnProperty(key)) {
        switch (preparedKey) {
          // case "court":
          // case "camera":
          //   value = !!value;
          //   break;
          case "session_time":
            value =
              typeof value === "string"
                ? value.replace(",", "-").split("-")
                : value;
            break;
          case "end_date":
          case "start_date":
            value = new Date(value);
            break;
          default:
            break;
        }
        obj[key] = value;
      }
    });
    return obj;
  });
};

const useColumnRequest = (initialColumns = [], setTableForm) => {
  const [data, setData] = useState(initialColumns);
  const [inVisibleColumns, setInVisibleColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const initialTableFrom = useRef([]);

  const { copiedRows, temporaryCopiedRows, wasPasted, copiedIncompleteRows } =
    useSelector((state) => state.dashboardRows);

  const { settings } = useSelector((state) => state.userSettings, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    //get user settings
    dispatch(getUserSettings());
    (async function () {
      try {
        const columnsRes = await getDashboardCourtColumnsApi();
        const visibleColumnsRes = await getInVisibleColumns();

        const initialTableForm = [
          ...initialColumns,
          ...columnsRes.data.data,
        ].reduce(
          (acc, val) => {
            switch (val.name) {
              case "action":
              case "settings":
                break;
              case "court":
                acc[val.accessor] = false;
                break;
              case "start_date":
              case "day":
                acc[val.accessor] = new Date();
                break;
              case "end_date":
                acc[val.accessor] = getFrequency(
                  `${acc.frequency}`,
                  new Date()
                );
                break;
              case "session_time":
                acc[val.accessor] = detectSessionTime(new Date());
                break;
              case "frequency":
                acc[val.accessor] = settings.frequency;
                break;
              default:
                acc[val.accessor] = "";
            }
            return acc;
          },
          { is_audio: settings.is_audio, exclude_dates: [], is_new: true }
        );
        initialTableFrom.current = initialTableForm;

        const formData = [
          { ...initialTableForm },
          { ...initialTableForm },
          { ...initialTableForm },
          { ...initialTableForm },
          { ...initialTableForm },
        ];

        if (copiedRows.length && wasPasted) {
          const preparedCopiedRows = prepareCopiedRows(
            copiedRows,
            initialTableFrom.current
          );

          setTableForm(changeNewTabData(preparedCopiedRows, formData));
        } else if (copiedIncompleteRows.length) {
          setTableForm(changeNewTabData(copiedIncompleteRows, formData));
        } else {
          setTableForm(formData);
        }

        columnsRes.data.data.forEach((el) => (el.Cell = SwitchCell));
        const cloneInitialData = [...initialColumns];
        const lastItem = cloneInitialData.pop();
        const last2Item = cloneInitialData.pop();

        setData([
          ...cloneInitialData,
          ...columnsRes.data.data,
          last2Item,
          lastItem,
        ]);
        setInVisibleColumns(visibleColumnsRes.data.data);
      } catch (err) {
        setError(err);
        console.error("GET COLUMNS AND VISIBLE COLUMNS", err);
      } finally {
        setLoading(false);
      }
    })();
  }, [initialColumns, copiedRows, settings.is_audio, settings.frequency]);

  return [
    data,
    inVisibleColumns,
    initialTableFrom.current,
    copiedRows,
    temporaryCopiedRows,
    loading,
    error,
  ];
};

const New = () => {
  const [tableForm, setTableForm] = useState([]);
  const [isCopiedSelected, setIsCopiedSelected] = useState(false);
  const [showExcludedDates, setShowExcludedDates] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [incompleteDataForm, setIncompleteDataForm] = useState([]);
  const [newRows, setNewRows] = useState(0);
  //state for audience popup
  const [showAudienceList, setShowAudienceList] = useState(false);
  //above two states better be moved to redux
  const [showPopConfirm, setShowPopConfirm] = useState(false); //state for popconfirm
  const [isTouched, setIsTouched] = useState(false); //to determine if there is change

  const { newSubmittedRows, newPastedRows } = useSelector(
    (state) => state.dashboardRows
  );
  const history = useHistory();
  const [currentDateDiapason, setCurrentDateDiapason] = useState({
    from: null,
    to: null,
  });
  const { copiedIncompleteRows } = useSelector((state) => state.dashboardRows);
  const [isShowDeleteConfirmationPopUp, setIsShowDeleteConfirmationPopUp] =
    useState({
      isShow: false,
      deletedRowId: null,
    });

  const [
    fetchedColumnsData,
    inVisibleColumns,
    initialTableFrom,
    copiedRows,
    temporaryCopiedRows,
    columnLoading,
  ] = useColumnRequest(initialColumnsData, setTableForm);

  const formData = [
    { ...initialTableFrom },
    { ...initialTableFrom },
    { ...initialTableFrom },
    { ...initialTableFrom },
    { ...initialTableFrom },
  ];

  const data = useMemo(() => tableForm, [tableForm]);
  const dispatch = useDispatch();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    allColumns,
    state,
    setHiddenColumns,
  } = useTable(
    {
      columns: fetchedColumnsData,
      data,
      tableForm,
      setTableForm,
      handleSubmitRow,
      setCurrentRowIndex,
      handleOpenExcludeDateModal,
      setShowAudienceList,
      initialState: {},
      autoResetSelectedRows: false,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
    useSticky,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push(insertColumns);
    }
  );

  useEffect(() => {
    if (!columnLoading && newPastedRows && copiedRows.length) {
      setNewRows(newPastedRows);
    }
  }, [columnLoading]);

  useEffect(() => {
    if (inVisibleColumns.length) {
      setHiddenColumns(inVisibleColumns);
    }
  }, [inVisibleColumns]);

  useEffect(() => {
    if (copiedIncompleteRows.length > 0) {
      setIncompleteDataForm([...copiedIncompleteRows, ...incompleteDataForm]);
      dispatch(copyItemsFromIncompleteRows([]));
    }
  }, [copiedIncompleteRows]);

  async function handleToggleHiddenProps(toggleHidden, columnId, isVisible) {
    try {
      await updateVisibleColumns(columnId, isVisible);
      toggleHidden();
    } catch (err) {
      console.error("COURT SHOW/HIDE ERROR", err);
    } finally {
    }
  }

  function handleAddNewRow() {
    setTableForm((old) => [...old, { ...initialTableFrom }]);
  }

  function handleDeleteSelected() {
    if (selectedFlatRows.length) {
      setIsShowDeleteConfirmationPopUp({
        deletedRowId: null,
        isShow: true,
      });
    }
  }

  function handleSubmitRow(rowIndex, rowData) {
    let newIncompleteRows = [...incompleteDataForm];
    newIncompleteRows.splice(rowIndex, 1);
    setIncompleteDataForm(newIncompleteRows);
    //filter row data exclude empty data and remove line break from email string
    let filteredRowData = {
      ...rowData,
      audience: rowData.audience
        ? rowData.audience
            .map((data) => {
              return {
                ...data,
                email: data.email
                  ? data.email.replace(/(\r\n|\n|\r)/gm, "")
                  : "",
              };
            })
            .filter((data) => {
              return data.first_name && data.last_name && data.email;
            })
        : [],
    };

    dispatch(addDashboardRow(filteredRowData)).then(() => {
      let newForm = [...tableForm];
      let newCopyForm = [...copiedRows];
      newForm.splice(rowIndex, 1);
      newCopyForm.splice(rowIndex, 1);
      copiedRows.length && dispatch(resetCopiedRows(newCopyForm));
      dispatch(setSubmittedRows(newSubmittedRows + 1));
      setTableForm(changeNewTabData(newForm, formData));
      localStorage.setItem("defaultSort", null);
    });
  }

  // function handleCopySelectedRows() {
  //   setIsCopiedSelected(true);
  //   const selectedRows = cloneDeep(selectedFlatRows.map((val) => val.original));
  //   dispatch(copyItems(selectedRows));
  // }

  const handleCopySelectedRows = () => {
    if (selectedFlatRows.length) {
      !isCopiedSelected && setIsCopiedSelected(true);
      dispatch(copyItems(selectedFlatRows));
    }
  };

  function handlePasteSelectedRows() {
    dispatch(wasPasted(true));

    if (temporaryCopiedRows.length) {
      dispatch(copyItemsFromTemporaryToBasic());
      dispatch(setPastedRows(temporaryCopiedRows.length));
      // const preparedCopiedRows = prepareCopiedRows(
      //   copiedRows,
      //   initialTableFrom
      // );
      // setTableForm((old) => [...old, ...preparedCopiedRows]);
    }
  }

  function handleOpenExcludeDateModal(dateDiapasonObj) {
    setCurrentDateDiapason(dateDiapasonObj);
    setShowExcludedDates(true);
  }

  function handleCloseExcludeDateModal(value) {
    setShowExcludedDates(value);
  }

  function handleResetCurrentDateDiapason(value) {
    const cloneTableForm = cloneDeep(tableForm);
    cloneTableForm[currentRowIndex].exclude_dates = [];
    setTableForm(cloneTableForm);
  }

  async function handleSubmitAllRows(value) {
    let { readyData, incompleteRows } = separateData(tableForm);

    let message =
      readyData.length > 0
        ? incompleteRows.length > 0
          ? "One or more rows are missing information and have not been submitted. Please go back to the '+ New' tab to see them."
          : ""
        : "Please complete your stream information before submitting.";
    try {
      await addDashboardAllRowsApi(tableForm);
      message && toast.error(message, { autoClose: false });
      setIncompleteDataForm([]);
      if (readyData.length) {
        history.push("/app");
        dispatch(setSubmittedRows(readyData.length));
      }
      if (incompleteRows.length) {
        setTableForm(changeNewTabData(incompleteRows, formData));
        dispatch(copyItemsFromIncompleteRows(incompleteRows));
      } else {
        setTableForm(formData);
        dispatch(copyItemsFromIncompleteRows([]));
      }

      copiedRows.length && dispatch(resetCopiedRows([]));
      localStorage.setItem("defaultSort", null);
    } catch (err) {
      console.error("SUBMIT ALL REQUEST ERROR", err);
    }
  }

  function handleConfirmationDelete() {
    let newForm = [...tableForm];
    selectedFlatRows.forEach(({ index }) => {
      newForm.forEach((_, idx, arr) => {
        if (index === idx) {
          delete arr[idx];
        }
      });
    });
    newForm = newForm.filter((val) => val !== null);
    setTableForm(newForm);
    setIsShowDeleteConfirmationPopUp({
      ...isShowDeleteConfirmationPopUp,
      isShow: false,
    });
  }

  function handleCancelConfirmationDelete() {
    setIsShowDeleteConfirmationPopUp({
      ...isShowDeleteConfirmationPopUp,
      isShow: false,
    });
  }

  function resetRows() {
    setTableForm(formData);
    setIncompleteDataForm([]);
    setNewRows(0);
    copiedRows.length && dispatch(resetCopiedRows([]));
  }

  return (
    <div className="new">
      <div className="new-tools">
        <ButtonWithIcon
          disabled={!selectedFlatRows.length}
          disableIcon={CopyWhiteIcon}
          icon={CopyIcon}
          className={"tool-btn"}
          onClick={handleCopySelectedRows}
        >
          Copy <span>&nbsp;{"Selected"}</span>
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={!temporaryCopiedRows.length}
          disableIcon={PasteWhiteIcon}
          icon={temporaryCopiedRows.length ? PasteWhiteIcon : PasteIcon}
          className={temporaryCopiedRows.length ? "paste-btn" : ""}
          onClick={handlePasteSelectedRows}
        >
          Paste
        </ButtonWithIcon>
        <ButtonWithIcon
          disabled={!selectedFlatRows.length}
          disableIcon={CrossRotateWhiteIcon}
          icon={CrossRotatedIcon}
          className={"tool-btn"}
          onClick={handleDeleteSelected}
        >
          Delete <span>&nbsp;{"Selected"}</span>
        </ButtonWithIcon>
        <Button className="add-row" onClick={handleAddNewRow}>
          <PlusIcon /> &nbsp; Add Row
        </Button>
        <Button className="reset-btn" onClick={resetRows}>
          Reset rows
        </Button>
        <DropDown
          className="showHideDropDown tool-btn"
          contentClassName="content"
          label="Show/Hide"
          icon={ShowHideIcon}
        >
          <div className="wrapper">
            {allColumns.map((column) => {
              if (
                column.name.includes("camera") ||
                column.name.includes("court")
              ) {
                return (
                  <div className="checkbox-container" key={column.id}>
                    <Checkbox
                      column={column}
                      handleToggleHiddenProps={handleToggleHiddenProps}
                    />
                  </div>
                );
              }

              return "";
            })}
          </div>
        </DropDown>

        <Button className="submit-all" onClick={handleSubmitAllRows}>
          Submit All
        </Button>
      </div>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        footerGroups={footerGroups}
        rows={rows}
        newRows={newRows}
        setNewRows={setNewRows}
        prepareRow={prepareRow}
        selectedFlatRows={selectedFlatRows}
        state={state}
        incompleteDataForm={incompleteDataForm}
      />
      <Modal
        isOpen={showAudienceList}
        handleOpen={setShowAudienceList}
        showPopConfirm={showPopConfirm}
        setShowPopConfirm={setShowPopConfirm}
        isTouched={isTouched}
      >
        <AudiencePopup
          isShown={showPopConfirm}
          setIsShown={setShowPopConfirm}
          isTouched={isTouched}
          setIsTouched={setIsTouched}
          currentRowIndex={currentRowIndex}
          tableForm={tableForm}
          setTableForm={setTableForm}
          setShowAudienceList={setShowAudienceList}
          eventName={
            currentRowIndex === 0 || currentRowIndex
              ? tableForm[currentRowIndex].event_name
              : ""
          }
        />
      </Modal>
      <Modal
        className="new-date-modal"
        isOpen={showExcludedDates}
        handleOpen={handleCloseExcludeDateModal}
      >
        <div className="wrapper">
          <div>
            <p>Click on a date to exclude it</p>
          </div>
          <RangeDate
            date={currentDateDiapason}
            setDate={setCurrentDateDiapason}
            tableForm={tableForm}
            setTableForm={setTableForm}
            currentRowIndex={currentRowIndex}
          />
          <div className="footer">
            <button
              className="clearBtn"
              onClick={handleResetCurrentDateDiapason}
            >
              Clear
            </button>
            {/*<Button*/}
            {/*  bgColor="black"*/}
            {/*  className="saveBtn"*/}
            {/*  // onClick={() => setShowExcludedDates(false)}*/}
            {/*>*/}
            {/*  Save*/}
            {/*</Button>*/}
          </div>
        </div>
      </Modal>
      <ConfirmationPopUp
        isShow={isShowDeleteConfirmationPopUp.isShow}
        description="Are you sure you want to delete?"
        negativeActonLabel="Cancel"
        positiveActionLabel="Confirm"
        handleNegativeCallBack={handleCancelConfirmationDelete}
        handlePositiveCallBack={handleConfirmationDelete}
      />
    </div>
  );
};

export default New;

const HeaderCell = ({ value }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {value}
    </div>
  );
};

const initialColumnsData = [
  {
    Header: () => <HeaderCell value="Event Name" />,
    accessor: "event_name",
    name: "event_name",
    sticky: "left",
    Cell: EventName,
    width: 150,
  },
  {
    Header: "Frequency",
    accessor: "frequency",
    name: "frequency",
    Cell: Frequency,
  },
  {
    Header: "Start Date",
    accessor: "start_date",
    name: "start_date",
    Cell: StartDate,
  },
  {
    Header: "End Date",
    accessor: "end_date",
    name: "end_date",
    Cell: EndDate,
  },
  {
    Header: "Day",
    accessor: "day",
    name: "day",
    Cell: Day,
  },
  {
    Header: "Session Time",
    accessor: "session_time",
    name: "session_time",
    maxWidth: 210,
    minWidth: 210,
    Cell: SessionTime,
  },
  {
    Header: "Settings",
    accessor: "settings",
    name: "settings",
    disableSortBy: true,
    maxWidth: 210,
    minWidth: 210,
    Cell: Settings,
  },
  {
    Header: "Action",
    accessor: "action",
    name: "action",
    disableSortBy: true,
    maxWidth: 210,
    minWidth: 210,
    Cell: Action,
  },
];
