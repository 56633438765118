import { useEffect } from "react";
import moment from "moment";

const SelectColumnFooterForDay = ({
  footerFormData,
  setFooterFormData,
  column,
}) => {
  useEffect(() => {
    const day = moment(footerFormData.start_date).format("dddd");
    if (
      (footerFormData.frequency === "2" || footerFormData.frequency === "4") &&
      footerFormData[column.id] !== "Various"
    ) {
      setFooterFormData({ ...footerFormData, [column.id]: "Various" });
    } else if (
      (footerFormData.frequency === "1" || footerFormData.frequency === "3") &&
      day !== footerFormData[column.id]
    ) {
      setFooterFormData({ ...footerFormData, [column.id]: day });
    }
  }, [footerFormData]);

  return <div className="filter-select-block">{footerFormData[column.id]}</div>;
};

export default SelectColumnFooterForDay;
