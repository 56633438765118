import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";

import { getFrequency, detectSessionTime } from "../../helpers";

import "./startDate.scss";

const StartDate = ({ value, tableForm, setTableForm, column, row }) => {
  const handleFormChange = (selectedDay) => {
    const cloneForm = [...tableForm];
    cloneForm[row.index].end_date = getFrequency(
      cloneForm[row.index].frequency,
      selectedDay
    );
    cloneForm[row.index].session_time = detectSessionTime(selectedDay);
    cloneForm[row.index].day = selectedDay;
    cloneForm[row.index][column.id] = selectedDay;
    setTableForm(cloneForm);
  };

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth());

  return (
    <DayPickerInput
      format="ll"
      placeholder="Choose Date"
      formatDate={formatDate}
      parseDate={parseDate}
      value={value}
      dayPickerProps={{
        selectedDays: value,
        fromMonth: lastMonth,
        disabledDays: {
          before: new Date(),
        },
      }}
      onDayChange={handleFormChange}
    />
  );
};

export default StartDate;
