import { arrowDown } from "assets/svg";

import { frequencySelectData } from "../../configs";
import { getFrequency, day } from "../../helpers";

import "./frequency.scss";

const Frequency = ({ value, tableForm, setTableForm, column, row }) => {
  const handleFromChange = (e) => {
    const { value } = e.target;
    const cloneForm = [...tableForm];
    cloneForm[row.index].end_date = getFrequency(
      value,
      cloneForm[row.index].start_date
    );
    cloneForm[row.index].day = day(value, cloneForm[row.index].start_date);
    cloneForm[row.index][column.id] = value;
    setTableForm(cloneForm);
  };

  return (
    <div className="frequency">
      <select
        value={value}
        className="frequency-select"
        onChange={handleFromChange}
      >
        {frequencySelectData.map((val, i) => (
          <option key={i} value={val.id}>
            {val.value}
          </option>
        ))}
      </select>
      <img onClick={() => {}} src={arrowDown} alt="Icon" />
    </div>
  );
};

export default Frequency;
