import React from "react";
import { ConnectedRouter } from "connected-react-router";
import PublicRoutes from "./PublicRoutes";
import UserPrivateRoutes from "./user/PrivateRoutes";
import AdminPrivateRoutes from "./admin/PrivateRoutes";

const Routes = ({ history, loggedIn, isHeaderToggleHide, role }) => {
  return (
    <ConnectedRouter history={history}>
      {loggedIn ? (
        role === "admin" ? (
          <AdminPrivateRoutes basePath="/admin" access={loggedIn} />
        ) : (
          <UserPrivateRoutes basePath="/app" access={loggedIn} isHeaderToggleHide={isHeaderToggleHide} />
        )
      ) : (
        <PublicRoutes access={loggedIn} />
      )}
    </ConnectedRouter>
  );
};

export default Routes;
