import { getAudienceApi, saveAudienceApi } from "api/user/audience";
import { CopyIconBlue } from "assets/svg";
import PlusIcon from "assets/svg/components/PlusIcon";
import SuccessIcon from "assets/svg/components/SuccessIcon";
import { Button, ButtonWithIcon, Loading } from "components";
import React, { useEffect, useRef, useState } from "react";
import {
  useFilters,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import ConfirmationWrapper from "./Components/ConfirmationWrapper/ConfirmationWrapper";
import Table from "./Components/Table/Table";
import CheckboxSimple from "../../../components/CheckboxSimple";

const columnsData = [
  {
    Header: "First name",
    accessor: "first_name",
  },
  {
    Header: "Last name",
    accessor: "last_name",
  },
  {
    Header: "Email",
    accessor: "email",
    disableSortBy: true,
  },
];

const readClipoardAndParseText = async () => {
  const text = await navigator.clipboard.readText();

  if (text.trim()) {
    let lines = text.split("\n");
    const data = [];
    lines.forEach((line) => {
      let cell = line.split("\t");
      let newCat = {
        first_name: cell[0],
        last_name: cell[1],
        email: cell[2],
      };
      data.push(newCat);
    });

    return data;
  }
};

const AudiencePopup = ({
  rowId,
  currentRowIndex,
  tableForm,
  setTableForm,
  eventName,
  isShown,
  setIsShown,
  setIsTouched,
  setShowAudienceList,
  isPast,
}) => {
  const ref = useRef(null); //ref to get popup body and focus on it
  const [checkBoxes, setCheckBoxes] = useState([
    { text: "When the stream is ready", checked: false, name: "is_now" },
    { text: "3 days before", checked: false, name: "is_1_week_before" },
    { text: "1 day before", checked: false, name: "is_1_day_before" },
    { text: "1 hour before", checked: false, name: "is_1_hour_before" },
  ]); //state for checkboxes
  const [showSave, setShowSave] = useState(false); //state to show success animation
  const [loading, setLoading] = useState(false); //state for loading
  const [rowsData, setRowsData] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      isInitial: true,
    },
  ]);
  const columns = React.useMemo(() => columnsData, []);
  const data = React.useMemo(() => rowsData, [rowsData]);

  const table = useTable(
    {
      columns,
      data,
      handleChangeDataValue,
      handlePasteData,
      rowsData,
      setRowsData,
      readClipoardAndParseText,
      setIsTouched,
      autoResetSelectedRows: false,
      isPast,
    },
    useFilters,
    useFlexLayout,
    useSortBy,
    useSticky,
    useRowSelect
  );

  const isInitial = data.some((obj) => obj.hasOwnProperty("isInitial"));

  function handlePasteData(newData) {
    setRowsData((old) => {
      if (isInitial) {
        if (newData.length > old.length) {
          return [...newData];
        } else {
          return [
            ...newData,
            ...old.slice(newData.length).map((value) => {
              const { isInitial, ...newValue } = value; //taking out isInitial property which means check on line 87 returns false
              return newValue;
            }),
          ];
        }
      } else {
        //filter out empty data
        //TODO filter out objects conditins
        return [
          ...old.filter((rowObj) => {
            return rowObj.first_name && rowObj.last_name && rowObj.email;
          }),
          ...newData,
        ];
      }
    });
  }

  function handleChangeDataValue(value, columnId, rowIndex) {
    setRowsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  }

  //listen to ctrl + v
  const handleKeyDown = async (e) => {
    e.stopPropagation();
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.ctrlKey || e.metaKey) && charCode === "v") {
      const dataFromClipboard = await readClipoardAndParseText();
      handlePasteData(dataFromClipboard);
      setIsTouched(true);
    }
  };

  const copyToClipBoard = () => {
    setIsShown(true);
    const string = rowsData
      .map((obj) => {
        const line =
          obj.first_name + "\t" + obj.last_name + "\t" + obj.email + "\n";
        return line;
      })
      .join("");

    navigator.clipboard.writeText(string);
  };

  //focus on audience-popup-body to listen to ctrl + v event
  useEffect(() => {
    ref.current.focus();
    //if there is row id it's current tab if no its a new tab
    if (rowId) {
      setLoading(true);
      getAudienceApi(rowId)
        .then((res) => {
          if (res.status === 200 && res.data.data.length > 0) {
            const result = res.data.data;
            //retreive rows info
            const newRowData = result.map((obj) => {
              return {
                first_name: obj.first_name,
                last_name: obj.last_name,
                email: obj.email,
              };
            });
            //set new rows data
            setRowsData(newRowData);
            //retreive notify data
            const newCheckboxes = result.reduce((acc, cur, i) => {
              acc["is_now"] = !!cur.is_now;
              acc["is_1_day_before"] = !!cur.is_1_day_before;
              acc["is_1_hour_before"] = !!cur.is_1_hour_before;
              acc["is_1_week_before"] = !!cur.is_1_week_before;
              return acc;
            }, {});
            //set new checkbox data
            setCheckBoxes((c) =>
              c.map((checkbox) => {
                return { ...checkbox, checked: newCheckboxes[checkbox.name] };
              })
            );
          } else if (res.data.data.length === 0) {
            setRowsData([
              ...rowsData,
              ...rowsData,
              ...rowsData,
              ...rowsData,
              ...rowsData,
            ]);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    } else {
      const newRowData = tableForm[currentRowIndex].audience || [
        ...rowsData,
        ...rowsData,
        ...rowsData,
        ...rowsData,
        ...rowsData,
      ];
      const newCheckboxes =
        tableForm[currentRowIndex].event_notify || checkBoxes;
      setRowsData(newRowData);
      //set new checkbox data
      setCheckBoxes((c) =>
        c.map((checkbox) => {
          return { ...checkbox, checked: !!newCheckboxes[checkbox.name] };
        })
      );
    }

    return () => {
      setCheckBoxes([]);
    };
    // eslint-disable-next-line
  }, []);

  //change checkbox value
  const changeCheckboxValue = (e, name) => {
    setCheckBoxes(
      checkBoxes.map((checkbox) => {
        return checkbox.name === name
          ? { ...checkbox, checked: e.target.checked }
          : checkbox;
      })
    );
    setIsTouched(true);
  };

  const saveAudience = () => {
    //make object evnetNotify for form
    const eventNotify = checkBoxes.reduce((acc, cur, i) => {
      acc[cur.name] = +cur.checked;
      return acc;
    }, {});

    const filteredRowData = rowsData.filter(
      (rowObj) => rowObj.first_name && rowObj.last_name && rowObj.email
    );
    const formData = {
      audience: filteredRowData,
      event_notify: eventNotify,
    };

    if (rowId) {
      saveAudienceApi(formData, rowId)
        .then((res) => {
          if (res.status === 204) {
            setShowSave(true); //start animation
            setIsTouched(false);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {});
    } else {
      setTableForm(
        tableForm.map((row, index) => {
          return index === currentRowIndex
            ? { ...row, audience: rowsData, event_notify: eventNotify }
            : row;
        })
      );
      setIsTouched(false);
      setShowSave(true);
    }
  };

  const addNewRow = () => {
    setRowsData([
      ...rowsData,
      {
        first_name: "",
        last_name: "",
        email: "",
      },
    ]);
  };

  return (
    <div
      className={
        !isPast
          ? "audience-popup-body"
          : "audience-popup-body audience-popup-body-past"
      }
      tabIndex="0"
      onKeyDown={!isPast ? handleKeyDown : undefined}
      ref={ref}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            className={
              !isPast
                ? "audience-table-wrapper"
                : "audience-table-wrapper disabled-table"
            }
          >
            <div className={"audience-table-header"}>
              <h1>{`${eventName} Audience List`}</h1>
              {!isPast ? (
                <Button className="audience-add-row" onClick={addNewRow}>
                  <PlusIcon /> &nbsp; Add Row
                </Button>
              ) : (
                <ButtonWithIcon
                  disabled={false}
                  icon={CopyIconBlue}
                  className="copy-to-board-btn"
                  onClick={copyToClipBoard}
                >
                  Copy to<span>&nbsp;{"Clipboard"}</span>
                </ButtonWithIcon>
              )}
            </div>
            <div className="table-wrapper">
              <Table {...table} />
            </div>
          </div>
          <div className="audience-notifications">
            <div
              className={
                !isPast
                  ? "audience-notifications-content"
                  : "audience-notifications-content disabled"
              }
            >
              <h1>Send notification</h1>
              {checkBoxes.map((checkbox, i) => {
                return (
                  <span key={i}>
                    <CheckboxSimple
                      checkbox={checkbox}
                      changeCheckboxValue={(e) =>
                        !isPast && changeCheckboxValue(e, checkbox.name)
                      }
                    />
                    <p>{checkbox.text}</p>
                  </span>
                );
              })}
            </div>
            {!isPast && (
              <div className="buttons-wrapper">
                <Button
                  className={!showSave ? "saveBtn" : "animateSaveBtn"}
                  onClick={saveAudience}
                >
                  <span>Save</span> <SuccessIcon setShowSave={setShowSave} />
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      <ConfirmationWrapper
        isPast={isPast}
        setIsTouched={setIsTouched}
        isShown={isShown}
        setIsShown={setIsShown}
        setShowAudienceList={setShowAudienceList}
        saveAudience={saveAudience}
      />
    </div>
  );
};

export default AudiencePopup;
