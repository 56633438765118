//login
export const LOGIN__SET_USER = "LOGIN__SET_USER";
export const LOGIN__SET_LOADING = "LOGIN__SET_LOADING";
export const LOGIN__SET_ERROR = "LOGIN__SET_ERROR";

//get court columns
export const COURT_COLUMNS__SET_ITEMS = "COURT_COULUMNS__SET_ITEMS";
export const COURT_COLUMNS__SET_LOADING = "COURT_COLUMNS__SET_LOADING";
export const COURT_COLUMNS__SET_ERROR = "COURT_COLUMNS__SET_ERROR";

//get court columns
export const USER_SETTINGS_GET = "USER_SETTINGS_GET";
export const USER_SETTINGS_CHANGE = "USER_SETTINGS_CHANGE";
//dashboard rows
export const DASHBOARD_ROWS__SET_ITEMS = "DASHBOARD_ROWS__SET_ITEMS";
export const DASHBOARD_ROWS__SET_ITEM = "DASHBOARD_ROWS__SET_ITEM";
export const DASHBOARD_ROWS__UPDATE_ITEM = "DASHBOARD_ROWS__UPDATE_ITEM";
export const DASHBOARD_ROWS__DELETE_ITEM = "DASHBOARD_ROWS__DELETE_ITEM";
export const DASHBOARD_ROWS__SET_LOADING = "DASHBOARD_ROWS__SET_LOADING";
export const DASHBOARD_ROWS__SET_ERROR = "DASHBOARD_ROWS__SET_ERROR";
export const DASHBOARD_ROWS__COPY_ITEMS = "DASHBOARD_ROWS__COPY_ITEMS";
export const DASHBOARD_ROWS__WAS_PASTED = "DASHBOARD_ROWS__WAS_PASTED";
export const DASHBOARD_ROWS__ADD_FROM_TEMPORARY_ITEMS_TO_BASIC =
  "DASHBOARD_ROWS__ADD_FROM_TEMPORARY_ITEMS_TO_BASIC";
export const DASHBOARD_COPIED_ROWS_RESET = "DASHBOARD_COPIED_ROWS_RESET";
export const DASHBOARD_SET_PASTED_ROWS = "DASHBOARD_SET_PASTED_ROWS";
export const DASHBOARD_SET_SUBMITTED_ROWS = "DASHBOARD_SET_SUBMITTED_ROWS";
export const DASHBOARD_ROWS_UPDATE_ITEM_BY_SOCKET = "DASHBOARD_ROWS_UPDATE_ITEM_BY_SOCKET"

//missing data rows
export const INCOMPLETE_ROWS__COPY_ITEMS = "INCOMPLETE_ROWS__COPY_ITEMS";

//user role
export const LOGIN__SET_USER_ROLE = "LOGIN__SET_USER_ROLE";

//admin
export const ADMIN_GET_USERS = "ADMIN_GET_USERS";
export const ADMIN_GET_USER = "ADMIN_GET_USER";
export const ADMIN_SET_PER_PAGE = "ADMIN_SET_PER_PAGE";
export const ADMIN_SET_PAGE = "ADMIN_SET_PAGE";
