import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  setUsersTablePage,
  setUsersTablePerPage,
} from "store/actions";
import { Modal } from "components";
import { Table, FieldText, FieldButton } from "components/Admin";
import { deleteUser } from "api";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { adminRedirect } from "store/actions/auth/login";

const Users = () => {
  const history = useHistory();
  const [allUsers, setAllUsers] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const [opendModal, setOpenModal] = useState(false);

  const { users, per_page, page } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAllUsers({per_page, page}));
  }, []);

  useEffect(() => {
    if (users.meta && users.links && users.data) {
      setAllUsers(users.data);
      setLinks(users.links);
      setMeta(users.meta);
      setTotalPages(Math.ceil(users.meta.total / users.meta.per_page));
    }
  }, [users]);

  const data = useMemo(() => allUsers, [allUsers]);

  const Frequencies = {
    1: "One Time",
    2: "Daily",
    3: "Weekly",
    4: "Monthly",
  };

  const initialColumnsData = [
    {
      id: 0,
      Header: "Club Name",
      accessor: "name",
      name: "name",
      Component: FieldText,
    },
    {
      id: 1,
      Header: "Email",
      accessor: "email",
      name: "email",
      Component: FieldText,
    },
    {
      id: 2,
      Header: "Number of Cameras",
      accessor: "number_of_cameras",
      name: "number_of_cameras",
      Component: FieldText,
    },
    {
      id: 3,
      Header: "Addt'l Contact 1 Name",
      accessor: "contact_1_name",
      name: "contact_1_name",
      Component: FieldText,
    },
    {
      id: 4,
      Header: "Addt'l Contact 1 Email",
      accessor: "contact_1_email",
      name: "contact_1_email",
      Component: FieldText,
    },
    {
      id: 5,
      Header: "Addt'l Contact 1 Enabled?",
      accessor: "contact_1_enable",
      name: "contact_1_enable",
      Component: FieldText,
      mapper: (row, accessor) => (row[accessor] === 1 ? "True" : "False"),
    },
    {
      id: 6,
      Header: "Addt'l Contact 2 Name",
      accessor: "contact_2_name",
      name: "contact_2_name",
      Component: FieldText,
    },
    {
      id: 7,
      Header: "Addt'l Contact 2 Email",
      accessor: "contact_2_email",
      name: "contact_2_email",
      Component: FieldText,
    },
    {
      id: 8,
      Header: "Addt'l Contact 2 Enabled?",
      accessor: "contact_2_enable",
      name: "contact_2_enable",
      Component: FieldText,
      mapper: (row, accessor) => (row[accessor] === 1 ? "True" : "False"),
    },
    {
      id: 9,
      Header: "Addt'l Contact 3 Name",
      accessor: "contact_3_name",
      name: "contact_3_name",
      Component: FieldText,
    },
    {
      id: 10,
      Header: "Addt'l Contact 3 Email",
      accessor: "contact_3_email",
      name: "contact_3_email",
      Component: FieldText,
    },
    {
      id: 11,
      Header: "Addt'l Contact 3 Enabled?",
      accessor: "contact_3_enable",
      name: "contact_3_enable",
      Component: FieldText,
      mapper: (row, accessor) => (row[accessor] === 1 ? "True" : "False"),
    },
    {
      id: 12,
      Header: "Default Audio",
      accessor: "is_audio",
      name: "is_audio",
      Component: FieldText,
      mapper: (row, accessor) => (row[accessor] === 1 ? "On" : "Off"),
    },
    {
      id: 13,
      Header: "Default Frequency",
      accessor: "frequency",
      name: "frequency",
      Component: FieldText,
      mapper: (row, accessor) => Frequencies[row[accessor]],
    },
    {
      id: 14,
      Header: "Edit",
      accessor: "",
      name: "EDIT",
      Component: FieldButton,
      onClick: (field) => history.push("/admin/user/edit/" + field.id),
    },
    {
      id: 16,
      Header: "Log in as",
      accessor: "",
      name: "LOG IN",
      Component: FieldButton,
      onClick: (field) => {
        handleRedirect(field.id);
      },
    },
    {
      id: 15,
      Header: "Delete",
      accessor: "",
      name: "DELETE",
      Component: FieldButton,
      onClick: (field) => {
        setDeleteId(field.id);
        setOpenModal(!opendModal);
      },
    },
  ];
  const handleConfirm = () => {
    deleteUser(deleteId).then(() => dispatch(getAllUsers({ per_page: per_page, page: 1 })));
    setOpenModal(!opendModal);
  };

  const handleRedirect = (id) => {
    localStorage.clear();
    dispatch(adminRedirect(id));
    history.push("/");
  };

  const onPerPageChange = (perPage) => {
    dispatch(setUsersTablePerPage(perPage))
    dispatch(getAllUsers({ per_page: perPage, page:1 }));
  };

  const handlePageChange=(pageNum)=>{
    dispatch(setUsersTablePage(pageNum))
    dispatch(getAllUsers({ per_page: per_page, page:pageNum }));
  }

  return (
    <div className="users-container">
      <div className="users-top-bar">
        <div />
        <h1>Club Accounts</h1>
        <button
          className="add-user-button"
          onClick={() => history.push("/admin/user/create")}
        >
          Create Club Account
        </button>
      </div>
      <Modal isOpen={opendModal} handleOpen={() => setOpenModal(!opendModal)}>
        <div className="admin-modal">
          <div>Are you sure you want to delete?</div>
          <button className="confirmation-button" onClick={handleConfirm}>
            YES
          </button>
        </div>
      </Modal>
      <Table
        columns={initialColumnsData}
        data={data}
        tableForm={allUsers}
        links={links}
        meta={meta}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        onPerPageChange={onPerPageChange}
      />
    </div>
  );
};
export default Users;
