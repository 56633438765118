import { NoticeIcon } from "assets/svg";

import "./empty.scss";

const Empty = ({ emptyText }) => {
  return (
    <div className="empty">
      <div className="empty-content">
        <NoticeIcon />
        <p className="empty-message">{emptyText}</p>
      </div>
    </div>
  );
};

export default Empty;
