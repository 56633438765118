import { Users } from "containers";
import UserCreate from "containers/Admin/Users/Action/UserCreate";
import UserEdit from "containers/Admin/Users/Action/UserEdit";

export const privateRoutes = [
  {
    path: "/",
    exact: true,
    component: Users,
  },
  {
    path: "/user",
    exact: true,
    component: Users,
  },
  {
    path: "/user/create",
    exact: true,
    component: UserCreate,
  },
  {
    path: "/user/edit/:id",
    exact: true,
    component: UserEdit,
  },
];
