import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { login } from "./auth";
import { userSettings, dashboardRows, courtColumns } from "./user";
import { users } from "./admin";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    login,
    courtColumns,
    dashboardRows,
    userSettings,
    users,
  });

export default createRootReducer;
