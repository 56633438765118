const initialState = (form) => {
  return ({
    account_info: [
      { name: "name", label: "Club Name", value: form?.name || "", type: "text" }, //input
      { name: "email", label: "Email", value: form?.email || "", type: "email" }, //input
      { name: "password", label: "Password", value: "", type: "password" }, //input
      {
        name: "password_confirmation",
        label: "Confirm Password",
        value: "",
        type: "password",
      }, //input
      {
        name: "number_of_cameras",
        label: "Number of Cameras",
        value: form?.number_of_cameras || 0,
        type: "select",
        options: [...Array(40).keys()].map((value) => {
          return { value: value + 1, text: value + 1 };
        }),
      }, //select
      {
        name: "logo",
        label: "Upload Logo (408 x 273 px)",
        value: form?.logo || "",
        type: "file",
      }, //file upload
    ],

    default_contacts: [
      {
        name: "contact_1_name",
        label: "Contact 1 Name",
        value: form?.contact_1_name || "",
        type: "text",
      }, //input
      {
        name: "contact_1_email",
        label: "Contact 1 Email",
        value: form?.contact_1_email || "",
        type: "email",
      }, //input
      {
        name: "contact_1_enable",
        label: "Contact 1 Enabled",
        value: (form?.contact_1_enable === 1 && 1) || 0,
        type: "switch",
      }, //switch
      {
        name: "contact_2_name",
        label: "Contact 2 Name",
        value: form?.contact_2_name || "",
        type: "text",
      }, //input
      {
        name: "contact_2_email",
        label: "Contact 2 Email",
        value: form?.contact_2_email || "",
        type: "email",
      }, //input
      {
        name: "contact_2_enable",
        label: "Contact 2 Enabled",
        value: (form?.contact_2_enable === 1 && 1) || 0,
        type: "switch",
      }, //switch
      {
        name: "contact_3_name",
        label: "Contact 3 Name",
        value: form?.contact_3_name || "",
        type: "text",
      }, //input
      {
        name: "contact_3_email",
        label: "Contact 3 Email",
        value: form?.contact_3_email || "",
        type: "email",
      }, //input
      {
        name: "contact_3_enable",
        label: "Contact 3 Enabled",
        value: (form?.contact_3_enable === 1 && 1) || 0,
        type: "switch",
      }, //switch
    ],
    stream_options: [
      {
        name: "is_audio",
        label: "Audio",
        value: form && form.id ? form.is_audio : 1,
        type: "switch",
      }, //switch
      {
        name: "frequency",
        label: "Default Frequency",
        value: (form?.frequency > 0 && form?.frequency) || 1,
        type: "select",
        options: [
          { value: 1, text: "One Time" },
          { value: 2, text: "Daily" },
          { value: 3, text: "Weekly" },
          { value: 4, text: "Monthly" },
        ],
      }, //select
    ],
  })
};
const prepareData = (data) => {
  if (data) {
    let copyData = { ...data };
    Object.keys(copyData).forEach((item) => {
      if (copyData[item] === "N/A") {
        copyData[item] = copyData[item].replace("N/A", "");
      }
    });
    return copyData;
  }
  return null;
};

export { initialState, prepareData };
