import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { routerMiddleware as createRouterMiddleware } from "connected-react-router";

import createRootReducer from "./reducers";
import init from "./init";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
const routerMiddleware = createRouterMiddleware(history);

let middlewares = [routerMiddleware, thunk];

if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
    middlewares = [ ...middlewares, logger ]
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

init(store);

export default store;
