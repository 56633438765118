import moment from "moment";

import "./sessionTime.scss";

const SessionTime = ({ value, tableForm, setTableForm, column, row }) => {
  // console.log(tableForm, row.index, column.id);
  const handleFormChange = (e) => {
    const { value, name } = e.target;
    const cloneForm = [...tableForm];
    const format = "HH:mm";
    let newTableForm;

    if (name === "start") {
      // console.log(moment(tableForm[row.index][column.id][1], format));
      const isAfterEndTime = moment(value, format).isAfter(
        moment(tableForm[row.index][column.id][1], format)
      );

      const isSameEndTime = moment(value, format).isSame(
        moment(tableForm[row.index][column.id][1], format)
      );

      newTableForm = cloneForm.map((innerRow, i) => {
        if (row.index === i) {
          return {
            ...innerRow,
            session_time:
              isAfterEndTime || isSameEndTime
                ? [value, moment(value, format).add(1, "hours").format("HH:mm")]
                : [value, innerRow.session_time[1]],
          };
        } else return innerRow;
      });
    } else {
      const isEarlierStartTime = moment(value, format).isBefore(
        moment(tableForm[row.index][column.id][0], format)
      );
      const isSameStartTime = moment(value, format).isSame(
        moment(tableForm[row.index][column.id][0], format)
      );

      newTableForm = cloneForm.map((innerRow, i) => {
        if (row.index === i)
          return {
            ...innerRow,
            session_time:
              isEarlierStartTime || isSameStartTime
                ? [
                    moment(value, format).subtract(1, "hours").format("HH:mm"),
                    value,
                  ]
                : [innerRow.session_time[0], value],
          };
        else return innerRow;
      });
    }
    setTableForm(newTableForm);
  };

  const [startValue, endValue] = value;

  return (
    <div className="sessionTime">
      <input
        type="time"
        name="start"
        value={startValue}
        onChange={handleFormChange}
      />
      <input
        type="time"
        name="end"
        value={endValue}
        onChange={handleFormChange}
      />
    </div>
  );
};

export default SessionTime;
