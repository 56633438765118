import moment from "moment";

export function getFrequency(frequency, newDate) {
  switch (frequency) {
    case "2":
      return moment(newDate).add(7, "days").toDate();
    case "3":
      return moment(newDate).add(28, "days").toDate();
    case "4":
      return moment(newDate).add(1, "month").toDate();
    default:
      return newDate;
  }
}

export function day(frequency, startDate) {
  switch (frequency) {
    case "2":
    case "4":
      return "Various";
    default:
      return startDate;
  }
}

export function detectSessionTime(newStartDate) {
  let sessionTime = {
    start_time: "",
    end_time: "",
  };

  let startDate = moment(newStartDate).set({
    hour: moment().format("H"),
    minute: 0,
    second: 0,
  });

  if (startDate.isSame(new Date(), "day")) {
    sessionTime.start_time = moment(startDate).add(1, "hours").toDate();
  } else if (startDate.isAfter(new Date(), "day")) {
    sessionTime.start_time = moment(startDate).toDate().setHours(8);
  }

  sessionTime.end_time = moment(sessionTime.start_time)
    .add(1, "hours")
    .toDate();

  return [
    moment(sessionTime.start_time).format("HH:mm"),
    moment(sessionTime.end_time).format("HH:mm"),
  ];
}

export function separateData(data) {
  let readyData = [];
  let incompleteRows = [];
  data.forEach((item) => {
    let cameras = 0;
    for (let prop in item) {
      const lowercaseProp = prop.toLowerCase();
      if (lowercaseProp.includes("camera") && item[prop]) {
        cameras++;
        break;
      }
    }
    if (item.event_name && cameras > 0) {
      readyData.push(item);
    } else if (item.event_name || cameras > 0) {
      incompleteRows.push({ ...item });
    }
  });
  return { readyData, incompleteRows };
}

export function changeNewTabData(newData, initialDataForm) {
  const newItemLength = newData.length >= 5 ? 5 : newData.length;

  return [...newData, ...initialDataForm.slice(0, 5 - newItemLength)];
}

export function detectError(data) {
  let name = "";
  let courtValues = [];
  data.cells.forEach((item) => {
    if (item.column.name === "court") {
      courtValues.push(item.value);
    } else if (item.column.name === "event_name") {
      name = item.value;
    }
  });

  return {
    error: !courtValues.filter((value) => value).length || !name,
    courtError: !courtValues.filter((value) => value).length,
    nameError: !name,
  };
}
