import React from "react";

const CopyUrlIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 15.2H13.6C14.4 15.2 15 14.5 15 13.8V2.4C15 1.6 14.3 1 13.6 1H6.00001C5.20001 1 4.60001 1.7 4.60001 2.4V3.1"
        stroke="#231F20"
        strokeWidth="1.686"
        strokeMiterlimit="10"
      />
      <path
        d="M11.5 7.60019V15.6002C11.5 16.4002 10.9 17.0002 10.1 17.0002H2.4C1.6 17.0002 1 16.4002 1 15.6002V5.10019C1 4.30019 1.6 3.7002 2.4 3.7002H8"
        stroke="#231F20"
        strokeWidth="1.686"
        strokeMiterlimit="10"
      />
      <path
        d="M11.4 7.7998H7.8V3.7998L11.4 7.7998Z"
        stroke="#231F20"
        strokeWidth="1.686"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyUrlIcon;
