import axios from "../axios";

export const getDashboardCourtColumnsApi = () => {
  return axios.get("/court/config");
};

export const getInVisibleColumns = () => {
  return axios.get("/court/visible");
};

export const updateVisibleColumns = (columnId, isVisible) => {
  return axios.put(`/court/${columnId}?is_show=${Number(!isVisible)}`);
};
