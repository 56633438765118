import "./loading.scss";

const Loading = ({ small }) => {
  return (
    <div className={!small ? "loading" : "loading-small"}>
      <div className={!small ? "lds-ring" : "lds-ring-small"}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
