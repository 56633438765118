import { Route, Redirect, useRouteMatch, Switch } from "react-router-dom";

import { Header } from "components";
import { privateRoutes as routes } from "./config";

const PrivateRoutes = ({ access, basePath, isHeaderToggleHide }) => {
  const match = useRouteMatch(basePath);

  if (access && match) {
    return (
      <>
        <Header isHeaderToggleHide={isHeaderToggleHide} />
        <Switch>
          <Route path={basePath}>
            {routes.map(({ path, exact, component: Component }) => (
              <Route key={path} path={`${match.path}${path}`} exact={exact} component={Component} />
            ))}
          </Route>
        </Switch>
      </>
    );
  }

  return <Redirect to="/" />;
};

export default PrivateRoutes;
