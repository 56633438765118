import "./settings.scss";
import { uploadPhotoIcon } from "assets/svg";
import Switch from "react-switch";
import { Button, ConfirmationPopUp, Modal } from "components";
import Title from "./Components/Title";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeUserSettings, getUserSettings } from "store/actions";
import { frequencySelectData } from "../New/configs";
import SelectBox from "./Components/SelectBox";
import PasswordIcon from "assets/svg/components/PasswordIcon";
import PasswordHideIcon from "assets/svg/components/PasswordHideIcon";
import UploadLogoComponent from "./Components/UploadLogoComponent";
import SuccessIcon from "assets/svg/components/SuccessIcon";
import SettingsInput from "./Components/SettingsInput";
// import { XIcon } from "assets/svg";

const Settings = () => {
  const dispatch = useDispatch();
  const [showSave, setShowSave] = useState(false); //state for save button animation
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isShow, setIsShow] = useState(false); //state to show/hide confirmation popup
  const [tempSelect, setTempSelect] = useState(null);
  const [image, setImage] = useState("");
  const { settings } = useSelector((state) => state.userSettings, shallowEqual);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (settings) {
      setFormData({ ...settings, password: null });
    }
  }, [settings]);

  useEffect(() => {
    if (settings && settings.logo) {
      setImage(settings.logo);
    }
  }, [settings]);

  const handleSelect = (field, value) => {
    if (field === "number_of_cameras") {
      setIsShow(true);
      setTempSelect({ field: field, value: value });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    dispatch(getUserSettings());
  }, [dispatch]);

  //state to show/hide modal, 1-is forgot password, 2-is changepassword
  const [modalState, setModalState] = useState(false);

  const uploadPhoto = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
    setModalState(false);
  };

  const handleSaveUserSettings = () => {
    const multiFormData = new FormData();
    Object.entries(formData).forEach((val) => {
      multiFormData.append(val[0], val[1]);
    });

    dispatch(changeUserSettings(multiFormData));
    setShowSave(true);
  };

  const removeLogo = () => {
    setImage("");
    setFormData({ ...formData, logo: "" });
    setModalState(false);
  };

  return (
    <div className="settings-app">
      <Modal
        isOpen={modalState}
        handleOpen={setModalState}
        className={"upload-logo"}
      >
        {
          <UploadLogoComponent
            setModalState={setModalState}
            removeLogo={removeLogo}
            uploadPhoto={uploadPhoto}
          />
        }
      </Modal>
      <div className="content">
        <div className="card">
          <Title title="Account Information" />
          <div className="card-content">
            <div className="field label-block">
              <label>
                Email Address
                <SettingsInput
                  type="email"
                  propname="email"
                  placeholder="Email Address"
                  value={formData.email || ""}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="field label-block">
              <div>
                <label>
                  Password
                  <div className="password-wrapper">
                    {passwordVisible ? (
                      <PasswordHideIcon
                        handleClick={() => setPasswordVisible(false)}
                      />
                    ) : (
                      <PasswordIcon
                        handleClick={() => setPasswordVisible(true)}
                      />
                    )}
                    <SettingsInput
                      type={!passwordVisible ? "password" : "text"}
                      placeholder="Password"
                      propname="password"
                      onChange={handleChange}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="field">
              <label>
                Club Name
                {/* <input type="text" placeholder="Club Name" /> */}
                <SettingsInput
                  type="text"
                  placeholder="Club Name"
                  propname="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="field number-of-camera">
              <label>
                Number of Cameras
                <div className="camera-select-div">
                  <select
                    value={formData.number_of_cameras}
                    onChange={(e) =>
                      handleSelect("number_of_cameras", Number(e.target.value))
                    }
                  >
                    {[...Array(40)].map((item, index) => {
                      return <option key={index}>{index + 1}</option>;
                    })}
                  </select>
                </div>
              </label>
            </div>
            <div className="field upload-block">
              {image ? (
                <img
                  src={image}
                  alt="logo"
                  onClick={() => setModalState(true)}
                />
              ) : (
                <label>
                  <input type="file" onChange={uploadPhoto} />
                  <img src={uploadPhotoIcon} alt="Icon" />
                  <p>
                    Upload club <br /> photo or logo
                  </p>
                </label>
              )}
            
            </div>
          </div>
        </div>
        <div className="card">
          <Title title="Additional Contacts" />
          <div className="card-content">
            <div className="field default-contacts">
              <label>
                Additional Contact 1
                <input
                  type="text"
                  placeholder="Email address 1"
                  value={formData.contact_1_email || ""}
                  onChange={(e) =>
                    handleChange("contact_1_email", e.target.value)
                  }
                />
              </label>
              <div className="tools">
                <Switch
                  width={49}
                  height={26}
                  onChange={(e) =>
                    handleChange(
                      "contact_1_enable",
                      Number(!formData.contact_1_enable)
                    )
                  }
                  checked={!!formData.contact_1_enable}
                  onColor="#000"
                  offColor="#A9A8A8"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="field default-contacts">
              <label>
                Additional Contact 2
                <input
                  type="text"
                  placeholder="Email address 2"
                  value={formData.contact_2_email || ""}
                  onChange={(e) =>
                    handleChange("contact_2_email", e.target.value)
                  }
                />
              </label>
              <div className="tools">
                <Switch
                  width={49}
                  height={26}
                  onChange={() =>
                    handleChange(
                      "contact_2_enable",
                      Number(!formData.contact_2_enable)
                    )
                  }
                  checked={!!formData.contact_2_enable}
                  onColor="#000"
                  offColor="#A9A8A8"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="field default-contacts">
              <label>
                Additional Contact 3
                <input
                  type="text"
                  placeholder="Email address 3"
                  value={formData.contact_3_email || ""}
                  onChange={(e) =>
                    handleChange("contact_3_email", e.target.value)
                  }
                />
              </label>
              <div className="tools">
                <Switch
                  width={49}
                  height={26}
                  onChange={() =>
                    handleChange(
                      "contact_3_enable",
                      Number(!formData.contact_3_enable)
                    )
                  }
                  checked={!!formData.contact_3_enable}
                  onColor="#000"
                  offColor="#A9A8A8"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <Title title="Livestream Options" />
          <div className="card-content">
            <div className="field livestream-options">
              <label>Default Audio</label>
              <div className="tools">
                <Switch
                  width={49}
                  height={26}
                  onChange={() =>
                    handleChange("is_audio", Number(!formData.is_audio))
                  }
                  checked={!!formData.is_audio}
                  onColor="#000"
                  offColor="#A9A8A8"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </div>
            <div className="field livestream-options">
              <label>Default Frequency</label>
              <div className="tools">
                {/* passing handleSettingChange function to SelectBox */}
                {/* passing defaultValue prop assuming settings should have frequency */}
                <SelectBox
                  handleSettingChange={handleChange}
                  defaultValue={formData.frequency}
                >
                  {frequencySelectData.map((frequency) => {
                    return (
                      <option value={frequency.id} key={frequency.id}>
                        {frequency.value}
                      </option>
                    );
                  })}
                </SelectBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        className={!showSave ? "saveBtn" : "animateSaveBtn"}
        onClick={handleSaveUserSettings}
      >
        <span>Save</span>
        <SuccessIcon setShowSave={setShowSave} />
      </Button>
      <ConfirmationPopUp
        isShow={isShow}
        description="
      You are about to change the number of cameras in your account configuration.This may cause some active livestreams to be deleted.
      Do you still want to proceed?
      "
        handlePositiveCallBack={() => {
          handleChange(tempSelect.field, tempSelect.value);
          setIsShow(false);
          setTempSelect(null);
        }}
        handleNegativeCallBack={() => {
          setIsShow(false);
          setTempSelect(null);
        }}
      />
    </div>
  );
};

export default Settings;
