import {
  ADMIN_GET_USERS,
  ADMIN_GET_USER,
  ADMIN_SET_PER_PAGE,
  ADMIN_SET_PAGE,
} from "store/constants";
import { getUsers } from "api";
import { getUser } from "api/admin/getUsers";

const actions = {
  setUsers: (payload) => ({
    type: ADMIN_GET_USERS,
    payload,
  }),
  setUser: (payload) => ({
    type: ADMIN_GET_USER,
    payload,
  }),
  setPage: (payload) => ({
    type: ADMIN_SET_PAGE,
    payload,
  }),
  setPerPage: (payload) => ({
    type: ADMIN_SET_PER_PAGE,
    payload
  }),
};

export const getAllUsers =
  ({ per_page, page }) =>
  async (dispatch) => {
    try {
      const response = await getUsers(per_page, page);
      dispatch(actions.setUsers(response));
    } catch (err) {
      //
    } finally {
    }
  };

export const getOneUser = (id) => async (dispatch) => {
  try {
    const response = await getUser(id);
    dispatch(actions.setUser(response.data));
  } catch (err) {
    //
  } finally {
  }
};

export const setUsersTablePerPage =(perPage)=>(dispatch)=>{
  dispatch(actions.setPerPage(perPage))
}

export const setUsersTablePage =(page)=>(dispatch)=>{
  dispatch(actions.setPage(page))
}
