import moment from "moment";

export const frequency_one_time = "1";
export const frequency_daily = "2";
export const frequency_weekly = "3";
export const frequency_monthly = "4";

export function frequency(frequency, newDate) {
  switch (frequency) {
    case frequency_daily:
      return (newDate.end_date = moment(newDate.start_date).add(7, "days").toDate());
    case frequency_weekly:
      return (newDate.end_date = moment(newDate.start_date).add(28, "days").toDate());
    case frequency_monthly:
      return (newDate.end_date = moment(newDate.start_date).add(1, "month").toDate());
    default:
      return newDate.end_date;
  }
}

export const detectSessionTime = newStartDate => {
  let sessionTime = {
    start_time: "",
    end_time: "",
  };

  let startDate = moment(newStartDate).set({
    hour: moment().format("H"),
    minute: 0,
    second: 0,
  });

  if (startDate.isSame(new Date(), "day")) {
    sessionTime.start_time = moment(startDate).add(1, "hours").toDate();
  } else if (startDate.isAfter(new Date(), "day")) {
    sessionTime.start_time = moment(startDate).toDate().setHours(8);
  }

  sessionTime.end_time = moment(sessionTime.start_time).add(1, "hours").toDate();

  return `${moment(sessionTime.start_time).format("hh:mm a")}-${moment(sessionTime.end_time).format("hh:mm a")}`;
};
