import React, { useState, useEffect } from "react";
import { editUser } from "api";
import { Button } from "components";
import { useHistory, useParams } from "react-router-dom";
import "./userAction.scss";
import { useDispatch, useSelector } from "react-redux";
import { getOneUser } from "store/actions/admin/users";
import { initialState, prepareData } from "../Components/UserHelper";
import UserForm from "../Components/UserForm";

const UserEdit = () => {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);

  const [formData, setFormData] = useState(initialState(prepareData([])));
  const [errorBorders, setErrorBorders] = useState([]);
  const [image, setImage] = useState("");

  useEffect(() => {
    setFormData(initialState(prepareData(user)));
  }, [user]);

  useEffect(() => {
    dispatch(getOneUser(id));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const multiFormData = new FormData();
    Object.entries(formData).forEach((arr) =>
      arr[1].forEach((obj) => multiFormData.append(obj.name, obj.value))
    );

    multiFormData.append("id", user.id);
    editUser(multiFormData, user.id)
      .then((res) => {
        if (res.status === 201) {
          setErrorBorders([]);
          setFormData(initialState);
        }
        history.goBack();
      })
      .catch((err) => {
        console.error(err.response.data);
        const { errors } = err.response.data;
        let newErrors = [];
        Object.keys(errors).forEach((key) => {
          newErrors.push(key);
        });
        setErrorBorders([...newErrors]);
      })
      .finally(() => {});
  };

  return (
    <div className="create-user-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
          alignItems: "center",
        }}
      >
        <div />
        <h1>Edit Club Account</h1>
        <img
          id="logo_img"
          src={image}
          alt=""
          style={{ maxHeight: "120px", maxWidth: "120px" }}
        />
      </div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="sections-wrapper">
          <UserForm
            formData={formData}
            setFormData={setFormData}
            user={user}
            errorBorders={errorBorders}
            setImage={setImage}
          />
        </div>
        <Button className="saveBtn" onClick={handleSubmit}>
          Save
        </Button>
      </form>
    </div>
  );
};

export default UserEdit;
