import React from "react";

const UploadLogoComponent = ({ setModalState, removeLogo, uploadPhoto }) => {
  return (
    <div className="upload-container">
      <div>Change club photo or logo</div>
      <button className="red" onClick={() => removeLogo()}>
        Remove current
      </button>
      <div className="blue">
        <input type="file" id="file" onChange={(e) => uploadPhoto(e)} />
        <label htmlFor="file">Upload new</label>
      </div>
      <button onClick={() => setModalState(false)}>Cancel</button>
    </div>
  );
};

export default UploadLogoComponent;
