import moment from "moment";
import DayPicker from "react-day-picker";
import cls from "classnames";
import cloneDeep from "lodash.clonedeep";

import { arrowRightIcon, arrowLeftIcon } from "assets/svg";

import "./rangeDate.scss";

function isLastDay(dt) {
  var test = new Date(dt.getTime()),
    month = test.getMonth();

  test.setDate(test.getDate() + 1);
  return test.getMonth() !== month;
}

function isFirstDay(dt) {
  return moment(dt).format("DD") === 1;
}

const RangeDate = ({ date, tableForm, setTableForm, currentRowIndex }) => {
  const handleDayClick = (day, modifiers) => {
    if (
      moment(day).isAfter(date.to) ||
      moment(day).add(1, "day").isBefore(date.from)
    ) {
      return;
    }
    const cloneTableForm = cloneDeep(tableForm);

    const uniqieExcludedDays = Array.from(
      new Set([
        ...cloneTableForm[currentRowIndex].exclude_dates,
        moment(day).format("YYYY-MM-DD"),
      ])
    );

    cloneTableForm[currentRowIndex].exclude_dates = [...uniqieExcludedDays];
    setTableForm(cloneTableForm);
  };

  const { from, to } = date;

  const modifiers = {
    start: from,
    end: to,
    excluded: (day) =>
      tableForm[currentRowIndex].exclude_dates.includes(
        moment(day).format("YYYY-MM-DD")
      ),
  };

  return (
    <div className="rangeDate">
      <DayPicker
        disabledDays={{ before: from, after: to }}
        fromMonth={from}
        toMonth={to}
        className="Selectable"
        initialMonth={date.from}
        numberOfMonths={2}
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        navbarElement={(params) => {
          const {
            onPreviousClick,
            onNextClick,
            showNextButton,
            showPreviousButton,
          } = params;

          return (
            <div className="DayPicker-navBar">
              {showPreviousButton && (
                <button className="previous" onClick={() => onPreviousClick()}>
                  <img src={arrowLeftIcon} alt="Icon" />
                </button>
              )}
              {showNextButton && (
                <button className="next" onClick={() => onNextClick()}>
                  <img src={arrowRightIcon} alt="Icon" />
                </button>
              )}
            </div>
          );
        }}
        weekdaysShort={["S", "M", "T", "W", "T", "F", "S"]}
        renderDay={(day) => {
          return (
            <div
              className={cls("DayPicker-CustomDay", {
                isFirst: isFirstDay(day),
                isLast: isLastDay(day),
              })}
              data-day={moment(day).format("D")}
            >
              {moment(day).format("D")}
            </div>
          );
        }}
        onDayClick={handleDayClick}
      />
    </div>
  );
};

export default RangeDate;
