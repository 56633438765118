import React, {useEffect, useMemo, useState} from "react";

import "./sessionTimeFooter.scss";
import {detectSessionTime} from "../../../../helpers/dashboard";
import moment from "moment";

const SessionTimeFooter = ({ footerFormData, setFooterFormData, column }) => {
  const sessionTime = useMemo(() => {
    return footerFormData[column.id] || detectSessionTime(footerFormData.start_date);
  },[footerFormData,column.id])

  const [selectedTime, setSelectedTime] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    setFooterFormData({
      ...footerFormData,
      [column.id]: `${selectedTime.start}-${selectedTime.end}`,
    });
  }, [selectedTime,column.id]);

  const handleChange = (e) => {
    const { value: time, name } = e.target;

    if (time !== "") {
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      let suffix = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      hours = hours < 10 ? "0" + hours : hours;

      let displayTime = hours + ":" + minutes + " " + suffix;
      let setTime = {
        start: selectedTime.start,
        end: selectedTime.end,
      }

      setTime[name] = displayTime
      let tempStartTime = moment(setTime.start, 'hh:mm a');
      let tempEndTime = moment(setTime.start, 'hh:mm a');

      if (tempStartTime.isAfter(tempEndTime) || !setTime.end || tempStartTime.isSame(tempEndTime)) {
        setTime.end = moment(setTime.start, 'hh:mm a').add(1,'hour').format('hh:mm a');
      }

      setSelectedTime({ ...selectedTime, ...setTime });
    }
  };

  return (
    <div className="session-time-block">
      <input
        type="time"
        name="start"
        value={
          sessionTime.split("-")[0]?.replace(/[^:0-9]/g, "") || ""
        }
        onChange={handleChange}
      />
      <input
        type="time"
        name="end"
        value={
          sessionTime.split("-")[1]?.replace(/[^:0-9]/g, "") || ""
        }
        onChange={handleChange}
      />
    </div>
  );
};

export default SessionTimeFooter;
